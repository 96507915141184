import React from 'react';
import { Row, Col } from 'antd';
import InvestmentOverview2 from './investmentOverview';

const Dashboard2 = () => {
  return (
    <div style={{
      backgroundColor: 'white'
    }}>
      <Row gutter={8}>
        <Col flex={'auto'}>
          <InvestmentOverview2 />
        </Col>
      </Row>
    </div >
  );
};

export default Dashboard2;
