// the button entry to personal profile
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Divider, Row, Col, Menu, Dropdown, Button } from 'antd';
import { LogoutOutlined, SettingOutlined, UserOutlined, } from '@ant-design/icons';
import PersonalProfile from '../PersonalProfile';

const SettingsButton = ({ changeContent, doLogout }) => {
  const user = useSelector(store => store.user);
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const menu = user ? (<>
    <Row style={{ boxShadow: '3px 5px 18px #bbb' }}>
      <Col>
        <Menu >
          <Menu.ItemGroup
            title={user.userProfile?.email}
          >
            <Menu.Item key="1">
              <Button type='link' icon={<SettingOutlined />} onClick={(e) => { changeContent && changeContent({ key: 'settings' }) }}>Settings</Button>
            </Menu.Item>
            <Menu.Item key="2">
              <Button type='link' icon={<LogoutOutlined />} onClick={(e) => { doLogout && doLogout(); }}>Logout</Button>
            </Menu.Item>

          </Menu.ItemGroup>
        </Menu>
      </Col>
    </Row>
  </>
  ) : (<></>);

  const handleVisibleChange = flag => setCollapsed(!flag);
  return (
    <div style={{ height: '46px', verticalAlign: 'top', marginTop: '-8px' }}>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        visible={!collapsed}
        onClick={toggleCollapsed}
        overlay={menu}
        trigger={['hover']}
      >
        <Avatar size={32} icon={<UserOutlined />} />
      </Dropdown>
    </div>
  );
};

export default SettingsButton;
