import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionLayout from '../../layouts/subscriptionLayout';
import { Radio, Alert, Button, Modal } from 'antd';
import { SUBSCRIPTIONMENU } from '../../utils/Constants';

const defaultValue = SUBSCRIPTIONMENU[0].value;

const Subscription = () => {
    const user = useSelector(store => store.user);
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [mode, setMode] = useState(user.mode)

    const setCurrentBlock = (e) => {
        setCurrentValue(e.target.value);
    }

    useEffect(() => {
        setMode(user.mode);
    }, [user]);

    const showDetail = (e) => {
        Modal.confirm({
            title: "INTERNAL Test mode",
            content: (
                <div>
                <p> The subscription functino is currently in internal teset mode.</p>
                <p> You can not use your own PayPal account to pay for the subscriptinos.</p>
                <p> Please contact website owner to get a testing PayPal account to subscribe.</p>
                </div>
            ),
            width: "800",
        });
    }
    {/*}
        <SubscriptionLayout>
        {*/}
    return (
        <>
        	{ mode == 'test' ? 
            <Alert banner type={"warning"} 
                message={<div><b>!!!IMPORTANT!!!</b> INTERNAL Test Mode.</div>}
                description={<div>The subscription function is currently in Test mode. All actions including payments are for testing. <b>NEVER</b> use your real PayPal account to subscribe.</div>}
                action={<Button onClick={showDetail}>Detail</Button>}
            />: <></> }
            < Radio.Group onChange={setCurrentBlock} defaultValue={defaultValue} size="large" buttonStyle='solid'>
                {
                    SUBSCRIPTIONMENU.map((menu, index) => (
                        <Radio.Button value={menu.value} key={index}>{menu.title}</Radio.Button>
                    ))
                }
            </Radio.Group >
            {
                SUBSCRIPTIONMENU.map((menuComponent, index) => (
                    currentValue === menuComponent.value && <menuComponent.component key={index} />
                ))
            }
        </>
    )
}

export default Subscription;