export const addMonth = (d, n) => {
    var year = d.getYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var newMonth = month + d;
    if (newMonth >= 12) {
        year += Math.floor(newMonth / 12);
        newMonth = newMonth % 12;
    } else if (newMonth < 0) {
        year += Math.floor(newMonth / 12);
        newMonth = newMonth % 12 + 12;
    }
    return new Date(year, newMonth, day);
}

export const addDay = (d, n) => {
    return new Date(d.getTime() + 24 * 60 * 60 * 1000 * n);
}

export const addYear = (d, n) => {
    return new Date(d.getFullYear() + n, d.getMonth(), d.getDate());
}

export const getLastDayOfMonth = (year, month) => {
    if (month > 11) {
        while (month > 11) {
            year++;
            month -= 12;
        }
    } else if (month < 0) {
        while (month < 0) {
            year--;
            month += 12;
        }
    }
    const thatDay = new Date(year, month + 1, 1);
    return addDay(thatDay, -1);
}

const dateToInt = (d) => {
    if (d == undefined) return 0;
    const year = d.getFullYear() ?? 0;
    const month = d.getMonth() ?? 0;
    const day = d.getDate() ?? 0;
    return year * 1000 + month * 100 + day;
}

export const dayCompare = (d1, d2) => {
    const v1 = dateToInt(d1);
    const v2 = dateToInt(d2);
    return d1 - d2;
}

export const dateToString = (d) => {
    if (d == undefined) return '';
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return year
        + '-' + ((month >= 9) ? (month + 1) : ("0" + (month + 1)))
        + '-' + ((day >= 9) ? day : ("0" + day));
}