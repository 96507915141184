export const PAYPAL_OPTIONS = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    components: "buttons",
    intent: "subscription",
    vault: true,
};

export const PAYPAL_BUTTON_STYLE = {
    shape: 'rect',
    color: 'blue',
    layout: 'horizontal',
    label: 'subscribe',
};
