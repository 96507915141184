import React, { useState, useRef, useCallback } from 'react';
import { Form, Row, Col, Input, Button, Checkbox, Steps, Modal } from 'antd';
import { UserOutlined, KeyOutlined, LockOutlined, SolutionOutlined, SmileOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { URLS } from '../../utils/Constants';
import { login, sendResetEmail } from '../../service';
import { setJwtToken } from '../../utils/token';
import Captcha from 'react-captcha-code';

const ResetPass = () => {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [validateFeedback, setValidateFeedback] = useState(false);
  const [failedField, setFailedField] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [form] = Form.useForm();
  const captchaRef = useRef();
  var theCaptcha = "";

  const handleChange = useCallback(
    (captcha) => {
      theCaptcha = captcha;
      console.log('captcha:', captcha);
    }, []);


  const onFinish = values => {
    login(values)
      .then(response => {
        const { success, token, message } = response.data;
        if (success) {
          setJwtToken(token);
//          history.push(admin ? URLS.ADMIN_DASHBOARD : URLS.ROOT);
          history.push(admin ? URLS.ADMIN_DASHBOARD : URLS.DASHBOARD6);
        } else {
          console.error(message);
        }
      })
      .catch(err => {
        const result = err.response.data;
        setFailedField(result.failedField);
        setErrorMessage(result.message);
      });
  };

  const handleFieldChange = (e) => {
    failedField && setFailedField(null);
    errorMessage && setErrorMessage(null);
    validateFeedback && setValidateFeedback(false);
  };

  const resetClick = (e) => {
    form.validateFields().then((fields) => {
      if (fields.validate != theCaptcha) {
        Modal.error({
          title: "Error",
          content: "Verification Code Error. Please try again",
          onOk: () => {
            captchaRef.current?.refresh();
          },
          onCancel: () => {
            captchaRef.current?.refresh();
          }
        });
        setFailedField("validate");
        setValidateFeedback(true);
        return;
      }
      if (fields.validate && (fields.validate == theCaptcha)) {
        setValidateFeedback(true);
        sendResetEmail({ email: fields.email }).then((response) => {
//          console.info('--response--');
//          console.info(response);
        });
      }
    })
  }

  const stepRender1 = () => {
    return (<>
      <Row gutter={80}><Col>&nbsp;</Col></Row>
      <Form
        onFieldsChange={handleFieldChange}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your Email!' },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
              ]}
              help={failedField === 'email' ? errorMessage : null}
            >
              <Input
                allowClear
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email address"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item
              name="validate"
              rules={[
                { required: true, message: 'Please input validate code!' },
              ]}
              hasFeedback={validateFeedback}
              validateStatus={failedField == 'validate' ? "error" : undefined}
              help={failedField === 'validate' ? errorMessage : null}
            >
              <Input
                allowClear
                prefix={<KeyOutlined className="site-form-item-icon" />}
                placeholder="Validatition code"
              />
            </Form.Item>
          </Col>
          <Col span={1} />
          <Col span={5}>
            <Captcha ref={captchaRef} charNum={4} onChange={handleChange}
              height={35}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" onClick={resetClick} className="login-form-button">
                Reset Password
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form >
    </>);
  };

  const stepRender2 = () => {
    return (<div>step2</div>);
  };

  const stepRender3 = () => {
    return (<div>step3</div>);
  };

  const stepRender4 = () => {
    return (<div>step4</div>);
  };

  const steps = [
    {
      title: 'Enter Email address',
      description: '',
      icon: (<UserOutlined />),
      render: stepRender1,
    },
    {
      title: 'Receive Email',
      description: '',
      icon: (<SolutionOutlined />),
      render: stepRender2,
    },
    {
      title: 'Reset Password',
      description: '',
      icon: (<LockOutlined />),
      render: stepRender3,
    },
    {
      title: 'Complete',
      description: '',
      icon: (<SmileOutlined />),
      render: stepRender4,
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <div style={{ background: 'white', padding: 10 }}>
            <Steps current={current} type={"navigation"} className="site-navigation-steps">
              {
                steps.map(item => (
                  <Steps.Step key={item.title} title={item.title} description={item.description} icon={item.icon} />
                ))
              }
            </Steps>
            <div className='steps-content'>{steps[current].render()}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResetPass;
