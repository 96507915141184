import React from 'react';
import MarketSignal from '../components/MarketSignal';
import DailyTip from '../components/DailyTip';

const BULLETIN_PAGES = [
    {
        key: '1',
        component: (<MarketSignal signals={['spy', 'qqq', 'mid', 'iwm', 'dia']} />),
    },
    {
        key: '4',
        component: (<MarketSignal signals={['xlb', 'xle', 'xlf', 'xli', 'xlk']} />),
    },
    {
        key: '5',
        component: (<MarketSignal signals={['xlp', 'xlre', 'xlu', 'xlv', 'xly']} />),
    },
    {
        key: '6',
        component: (<MarketSignal signals={['xme', 'xop', 'xrt', 'xtl',]} />),
    },
];

export default BULLETIN_PAGES;