//stock, bond allocation, current YTD based on allocation and YTD trend line compared to SPY
import React, { useState } from 'react';
import { Row, Col, } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFundsData } from '../../service';
import Watchlists from '../Watchlists';
import DecileChart from '../DecileChart';
import { addFundsData } from '../../redux/reducers/user';
import { getDecileData, fixDecileData, } from '../../utils/fundUtils';
import { LINE_COLOR, LINE_COLOR_2 } from '../../constants/colors';

const InvestmentOverview3 = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);
  const flag = useSelector(store => store.flag);
  const { fundsData } = user || { fundsData: [] };
  const watchlist = useSelector(store => store.watchlist);
  const watchlistRoth = useSelector(store => store.watchlistRoth);
  const [activeWatchlist, setActiveWatchlist] = useState('401K');

  const checkFundsInWatchlist = () => {
    const funds = user.fundsData.map(f => f.code);
    var ret = [];
    watchlist.forEach(w => {
      if (funds.indexOf(w.code) < 0) {
        ret.push(w.code);
      }
    })
    watchlistRoth.forEach(w => {
      if (funds.indexOf(w.code) < 0) {
        ret.push(w.code);
      }
    })
    return ret;
  }

  React.useEffect(() => {
    if (user != undefined && user.fundsData != undefined && watchlist != undefined) {
      const missingFunds = checkFundsInWatchlist();
      if (missingFunds.length > 0) {
        fetchFundsData(missingFunds).then((res) => {
          dispatch(addFundsData(res.data.fundsData));
        });
      }
    }
    if (flag && flag['ACTIVE_WATCHLIST'] && activeWatchlist != flag['ACTIVE_WATCHLIST']) setActiveWatchlist(flag['ACTIVE_WATCHLIST']);
  }, [watchlist, watchlistRoth, flag]);

  const watchlistDecile = getDecileData(activeWatchlist == '401K' ? watchlist : watchlistRoth, fundsData);
  const watchlistDecileFixed = fixDecileData(watchlistDecile);

  return user ? (
    <>
      <Row gutter={[20, 20]} justify='center'>
        <Col flex={'1350px'}>
          <Watchlists
            watchlistProps={{
              showCategory: false,
              showDecile: false,
              showScore: false,
              showMonthDecile: true,
              showRecommendation: true,
            }}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]} >
        <Col span={24}>&nbsp;
        </Col>
      </Row>
      <Row gutter={[20, 20]} justify='center'>
        <Col flex={'1350px'}>
          <DecileChart
            data={watchlistDecileFixed}
            lineColor={watchlist.length <= 10 ? LINE_COLOR : LINE_COLOR_2}
            smooth={true}
          />
        </Col>
      </Row>
    </>
  ) : null;
};
export default InvestmentOverview3;
