import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleWatchlist from './SimpleWatchlist';
import NormalWatchlist from './NormalWatchlist';
import FasionWatchlist from './FasionWatchlist';


const Watchlist = (props) => {
    const { type = "simple" } = props;
    const { listName } = props;
    const user = useSelector(store => store.user);
    const fundsData = user.fundsData;
    const watchlist = useSelector(store => store.watchlist);
    const watchlistRoth = useSelector(store => store.watchlistRoth);

    const getWatchlistData = (key) => {
        if (key == '401K') return watchlist;
        if (key == 'ROTH') return watchlistRoth;
        return [];
    }

    const getWatchlist = (type) => {
        if (type == "dummy") {
            return (<></>);
        }
        if (type == 'simple') {
            return (<SimpleWatchlist {...props} />);
        }
        if (type == 'normal') {
            return (<NormalWatchlist fundsData={fundsData} {...props} />);
        }
        if (type == 'fasion') {
            return (<FasionWatchlist {...props} />);
        }
    }

    return getWatchlist(type);
}

export default Watchlist;