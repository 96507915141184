import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { LockOutlined, KeyOutlined } from '@ant-design/icons';
import Register from './components/Register';
import Login from './components/Login';
import Recovery from './components/Recovery';
import ResetPass from './components/ResetPass';
import Dashboard from './components/Dashboard';
import Dashboard2 from './components/Dashboard2';
import Dashboard3 from './components/Dashboard3';
import Dashboard4 from './components/Dashboard4';
import Dashboard5 from './components/Dashboard5';
import Dashboard6 from './components/Dashboard6';
import DefaultLayout from './layouts';
import DefaultLayout2 from './layouts/defaultLayout2';
import SimpleLayout from './layouts/simpleLayout';
import OnBoard from './components/OnBoard';
import { URLS } from './utils/Constants';
import './App.css';
import FundResearch from './components/FundResearch';
import './config/axiosConfig';
import PersonalProfile from './components/PersonalProfile';
import AppRootControler from './components/appRootControler';
import Logout from './components/Logout';
import ServerError from './components/serverError';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={URLS.LOGIN}>
          <SimpleLayout>
            <Row>
              <Col flex={'400px'} push={14}>
                <Card title={(<><LockOutlined /> View Your Account</>)} style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}>
                  <Login />
                </Card>
              </Col>
            </Row>
          </SimpleLayout>
        </Route>
        <Route exact path={URLS.LOGOUT}>
          <Logout />
        </Route>
        <Route exact path={URLS.REGISTER}>
          <SimpleLayout>
            <Row justify='center'>
              <Col flex={'800px'} style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}>
                <Card
                  title={(<><LockOutlined /> Register To SQVest.com</>)}
                  style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}
                  extra={<a href='/'>HOME</a>}
                >
                  <Register />
                </Card>
              </Col>
            </Row>
          </SimpleLayout>
        </Route>
        <Route exact path={URLS.RECOVERY}>
          <SimpleLayout>
            <Row justify='center'>
              <Col flex={'800px'} style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}>
                <Card
                  title={(<><KeyOutlined /> Forgot Your Password?</>)}
                  style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}
                  extra={<a href='/'>HOME</a>}
                >
                  <Recovery />
                </Card>
              </Col>
            </Row>
          </SimpleLayout>
        </Route>
        <Route exact path={URLS.RESETPASS}>
          <SimpleLayout>
            <Row justify='center'>
              <Col flex={'800px'} style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}>
                <Card
                  title={(<><LockOutlined /> Reset Password of Your Account</>)}
                  style={{ background: '#eee', boxShadow: '9px 18px 18px #222' }}
                  extra={<a href='/'>HOME</a>}
                >
                  <ResetPass />
                </Card>
              </Col>
            </Row>
          </SimpleLayout>
        </Route>
        <Route exact path={URLS.ON_BOARD}>
          <SimpleLayout>
            <OnBoard />
          </SimpleLayout>
        </Route>
        <Route exact path={URLS.ON_BOARD}>
          <SimpleLayout>
            <OnBoard />
          </SimpleLayout>
        </Route>
        <Route exact path={URLS.SERVER_ERROR}>
          <ServerError />
        </Route>
        <AppRootControler>
          <Route exact path={URLS.DASHBOARD6}>
            <DefaultLayout2>
              <Dashboard6 />
            </DefaultLayout2>
          </Route>
          <Route exact path={URLS.ROOT}>
            <DefaultLayout2>
              <Dashboard6 />
            </DefaultLayout2>
          </Route>
          <Route exact path={URLS.DASHBOARD2}>
            <DefaultLayout>
              <Dashboard2 />
            </DefaultLayout>
          </Route>
          <Route exact path={URLS.DASHBOARD3}>
            <DefaultLayout>
              <Dashboard3 />
            </DefaultLayout>
          </Route>
          <Route exact path={URLS.DASHBOARD4}>
            <DefaultLayout>
              <Dashboard4 />
            </DefaultLayout>
          </Route>
          <Route exact path={URLS.DASHBOARD5}>
            <DefaultLayout>
              <Dashboard5 />
            </DefaultLayout>
          </Route>
          <Route exact path={URLS.WELCOME}>
            <DefaultLayout>
              <Link to={URLS.DASHBOARD6}>Welcome &amp; Get Started</Link>
            </DefaultLayout>
          </Route>
          <Route path={URLS.FUND_RESEARCH}>
            <DefaultLayout>
              <FundResearch />
            </DefaultLayout>
          </Route>
          <Route path={URLS.ACCOUNT}>
            <DefaultLayout>
              <PersonalProfile />
            </DefaultLayout>
          </Route>
          <Route path={URLS.PAY}>
            <DefaultLayout>Pay subscription</DefaultLayout>
          </Route>
        </AppRootControler>
      </Switch>
    </Router>
  );
}

export default App;
