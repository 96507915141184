import Register from '../components/Register';
import Onboard from '../components/OnBoard';
import { JobDescription, AgeDescription, RetireAgeDescription, AssetAllocation, AnnualContribution, InvestmentStyle, FundCandidates } from '../components/CardForms';
import BillingHistory from '../components/Subscription/billingHistory';
import PaymentMethod from '../components/Subscription/paymentMethod';
import SubscriptionSelection from '../components/Subscription/subscriptionSelection';

export const URLS = {
  ROOT: '/',
  WELCOME: '/welcome',
  ACCOUNT: '/settings',
  DASHBOARD: '/dashboard6',
  DASHBOARD2: '/dashboard2',
  DASHBOARD3: '/dashboard3',
  DASHBOARD4: '/dashboard4',
  DASHBOARD5: '/dashboard5',
  DASHBOARD6: '/dashboard6',
  REGISTER: '/register',
  LOGIN: '/login',
  FUND_RESEARCH: '/fundResearch',
  ON_BOARD: '/onboard',
  PAY: '/pay',
  RECOVERY: '/recovery',
  RESETPASS: '/resetpass',
  LOGOUT: '/logout',
  SERVER_ERROR: '/serverError',
  WELCOME2: '/welcome2',
};

export const TOKEN_KEY = 'weifundToken';

export const pages = [
  {
    path: '/register',
    component: Register,
    isExact: true
  },
  {
    path: '/onboard',
    component: Onboard,
    isExact: true
  }
]

export const steps = [
  /*
  {
    title: 'Job',
    description: 'Tell us your job.',
    content: JobDescription
  },
  {
    title: 'Age',
    description: 'How old are you now?',
    content: AgeDescription
  },
  */
  {
    title: 'Retirement Age',
    description: 'When do you plan to retire?',
    content: RetireAgeDescription
  },
  {
    title: 'Assert Allocation',
    description: 'How is your asset allocation diversified?',
    content: AssetAllocation
  },
  /*
  {
    title: 'Annual Contribution',
    description: 'How much do your contribute annual contribution to your 401K?',
    content: AnnualContribution
  },
  */
  {
    title: 'Investment Style',
    description: 'Are you aggressive or a goal conservative investor?',
    content: InvestmentStyle
  },
  {
    title: 'Funds Candidates',
    description: 'Please choose the funds available to your 401K provider.',
    content: FundCandidates
  }
];

export const SUBSCRIPTIONMENU = [
  {
    value: 'billing history',
    title: 'Billing History',
    component: BillingHistory,
  },
  /*
  {
    value: 'payment method',
    title: 'Payment Method',
    component: PaymentMethod
  },
  */
  {
    value: 'subscription',
    title: 'Subscription',
    component: SubscriptionSelection,
  }
]
