const utilities = {
    changeFieldsAndValues: (fieldsObj) => {
        if (Object.keys(fieldsObj).length > 1) {
            return { 'allocation': fieldsObj }
        }
        else {
            return fieldsObj;
        };
    },
    addKeyToFunds: (funds) => {
        return funds.map((fund, index) => {
            return { key: index, fund: fund.fund }
        });
    },
    generateYearList: () => {
        const currentYear = new Date().getFullYear();
        return [... new Array(10)].map((_, index) => {
            return currentYear + index;
        })
    },
    creditCardNumberFormat: (cardNumber) => {
        return cardNumber.replace(/.(?=.{4})/g, 'x');
    }

};

export default utilities;