import React, { useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Row, Col, Table, Anchor, Typography } from 'antd';
import { Link } from 'react-router-dom';
import ProTable from '@ant-design/pro-table';
import Decile from './Decile';
import Score from './Score';
import getColumns from './columns';
import { getBaseConfig } from './configs';
import './watchlist.css';

const { Text, } = Typography;

const getConfig = (props) => {
    const config = {
        showDecile: true,
        showScore: true,
        showCategory: true,
        ...getBaseConfig(props),
        codeConfig: {
            // eslint-disable-next-line react/display-name
            render: (text, record, index) => {
                // eslint-disable-next-line react/display-name
                return <Link bounds={0} to={'#'} onClick={(e) => { return props?.codeConfig?.onClick(text, record, index); }}><font style={{ fontSize: 12, color: 'blue' }}>{text}</font></Link>;
            },
        },
        decileConfig: {
            // eslint-disable-next-line react/display-name
            render: (text, record, idnex) => {
                return <Decile value={text} />;
            },
        },
        scoreConfig: {
            // eslint-disable-next-line react/display-name
            render: (text, record, idnex) => {
                return <Score value={text} />;
            },

        },
        showName: false,
    };
    return config;
}

const FasionWatchlist = (props) => {
    const { fundList } = props;
    const dispatch = useDispatch();

    const config = getConfig(props);
    const columns = getColumns(config, { dispatch });
    const data = fundList;

    const getTitle = (m) => {
        return (<span>title</span>);
    };

    // eslint-disable-next-line react/display-name
    return (data && data.length ? <ProTable
        columns={columns}
        bordered={false}
        dataSource={data}
        size={"small"}
        className={"WatchlistTable"}
        {...config}
        expandable={{
            // eslint-disable-next-line react/display-name
            expandedRowRender: record => (<Row key={record.fund} ><Col offset={5} colSpan={11}><Text key={record.fund} ellipsis={{ tooltip: true }}><span style={{ fontSize: 10, color: '#666666' }}>{record.name}</span></Text></Col></Row>),
            defaultExpandAllRows: true,
            expandedRowClassName: (record, index, indent) => { return "WatchlistExpandLine" },
            showExpandColumn: false,
        }}
    /> : <div />);
}

export default FasionWatchlist;