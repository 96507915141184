import React, { useState } from 'react';
import { Row, Col, Button, Typography } from 'antd';
const { Text, Title } = Typography;

// eslint-disable-next-line react/display-name
const Decile = (props) => {
    const { value } = props;

    // eslint-disable-next-line react/display-name
    return <Row ><Col span={24}><span style={{ fontSize: 12, align: 'center', fontWeight: 'bold' }}>{value}</span></Col></Row>
};
export default Decile;