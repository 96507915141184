import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Affix, Tabs, Drawer, Statistic, Typography, Spin } from 'antd';
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { watchlistKeys } from '../../constants/funds';
import Watchlist from '../Watchlist';
import FundDetailPopup from '../FundDetailPopup';
import './drawer.css';
import WatchlistDrawer from './WatchlistDrawer';

const { TabPane } = Tabs;
const WatchlistTab = (props) => {
    const { tabPosition, withDrawer = false, open = undefined, openHandle, closeHandle } = props;
    const user = useSelector(store => store.user);
    const flag = useSelector(store => store.flag);
    const watchlist = useSelector(state => state.watchlist);
    const watchlistRoth = useSelector(state => state.watchlistRoth);
    const [activeWatchlist, setActiveWatchlist] = useState(flag['ACTIVE_WATCHLIST'] ?? '401K');
    const [fundDetail, setFundDetail] = useState({ show: false });
    const {
        showName = false,
        showDecile = true,
        showMonthDecile = false,
        groupMonthDecile = false,
        showMonthReturn = false,
        groupMonthReturn = false,
    } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if (flag && flag['ACTIVE_WATCHLIST'] && activeWatchlist != flag['ACTIVE_WATCHLIST']) setActiveWatchlist(flag['ACTIVE_WATCHLIST']);
    }, [flag])

    const getWatchlist = (key) => {
        if (key == '401K') return watchlist;
        if (key == 'ROTH') return watchlistRoth;
        return [];
    }

    const getWatchlistCount = (key) => {
        const theWatchlist = getWatchlist(key) ?? [];
        const length = theWatchlist.length;
        if (length == 0) return (<>no funds.</>)
        if (length == 1) return (<>1 fund.</>)
        return (<>{length} funds</>);
    }

    const getTabContent = (key) => {
        return (<>
            <Row wrap={false} style={{ padding: '0 0' }}>
                <Col flex="1 1 200px" >
                    <Row><span style={{ fontSize: 20, fontWeight: 'bold' }}>{key}</span></Row>
                    <Row ><span style={{ fontColor: 'black' }}>{getWatchlistCount(key)}</span></Row>
                    <Row> </Row>
                </Col>
                <Col flex="0 1 300px">
                    &nbsp;
                </Col>
            </Row >
        </>)
    }

    const changeWatchlist = (key) => {
        setActiveWatchlist(key);
        if (key == '401K') dispatch({ type: 'ACTIVATE_401K' });
        else if (key == 'ROTH') dispatch({ type: 'ACTIVATE_ROTH' });
        else dispatch({ type: 'ACTIVATE_401K' });
    }
    const getActiveWatchlist = () => {
        const ret = getWatchlist(activeWatchlist);
        return ret;
    }
    const codeClick = (text, record, index) => {
        setFundDetail({ code: text, show: true });
    }

    return user && getActiveWatchlist() ? (
        <>
            <Row>
                <Col flex={'auto'} >
                    <Tabs type='card'
                        tabBarGutter={24} activeKey={activeWatchlist} onChange={changeWatchlist}
                        tabBarExtraContent={
                            (open != undefined && open)
                                ? (<LeftCircleOutlined style={{ fontSize: '30px', color: '#1890FF' }} onClick={(e) => closeHandle()} />)
                                : (<RightCircleOutlined style={{ fontSize: '30px', color: '#1890FF' }} onClick={(e) => openHandle()} />)
                        }
                    >
                        tabPosition={tabPosition}
                        {watchlistKeys.map((key) =>
                            <TabPane key={key} tab={getTabContent(key)} style={{ overflow: 'visible' }}>
                                <Row key={key} style={{ overflow: 'visible' }}>
                                    <Col span='22'>
                                        <Watchlist
                                            headerTitle={(<span style={{ color: '#aaa' }}>Watchlist {activeWatchlist}</span>)}
                                            type="normal"
                                            showName={showName}
                                            showDecile={showDecile}
                                            showCategory={false}
                                            showMonthDecile={showMonthDecile}
                                            groupMonthDecile={groupMonthDecile}
                                            showMonthReturn={showMonthReturn}
                                            groupMonthReturn={groupMonthReturn}
                                            listName={activeWatchlist}
                                            fundList={getActiveWatchlist()}
                                            codeConfig={{ onClick: codeClick }}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            </Row>
            <FundDetailPopup
                show={fundDetail?.show}
                listName={activeWatchlist}
                fundCode={fundDetail?.code}
                onCancel={(e) => setFundDetail({ show: false })}
            />
            <div style={{ backgroundColor: 'white' }}>
            </div>
        </>) : (<></>);
};

export default WatchlistTab;
