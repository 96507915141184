// search funds and display preview of the fund, choose favorite funds and all
import React, { useState } from 'react';
import {
  AutoComplete,
  Input,
  Popover,
  Statistic,
  Row,
  Col,
  message,
  Button
} from 'antd';
import { HeartFilled, HeartOutlined, UnlockFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addTicker, removeTicker } from '../../redux/reducers/watchlist';
import {
  addToWatchlist,
  removeFromWatchlist,
  searchFunds,
  searchFundsDuringOnboard,
} from '../../service';
import { addTickerRoth, removeTickerRoth } from '../../redux/reducers/watchlistRoth';

const { Search } = Input;

const getContent = fund => {
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Statistic title="Wei Score" value={fund.total_score} />
      </Col>
      <Col span={12}>
        <Statistic title="Decile" value={fund.decile} />
      </Col>
      <Col span={12}>
        <Statistic title="YTD" value={`${fund.ytd}%`} />
      </Col>
    </Row>
  );
};

const Emphasize = ({ content, word }) => {
  if (content == undefined) return (<span></span>);
  if (word == undefined || word.length == 0) return (<span>{content}</span>);
  var re = new RegExp(word, "ig");
  var result = "";
  const len = word.length;
  for (var i = 0; i < content.length; ++i) {
    if (content.substr(i, len).search(re) == -1) {
      result += content.substr(i, 1);
      continue;
    }
    //    result += (<span style={{ color: "red" }}>{content.substr(i, len)}</span>)
    result += '<span style="color: red">' + content.substr(i, len) + '</span>';
    i += len - 1;
  }
  return (<span dangerouslySetInnerHTML={{ __html: result }} />)
}

const SearchResultItem = ({ fund, disableLike = false, onboard = true, emphasize }) => {
  const watchlist = useSelector(state => state.watchlist);
  const watchlistRoth = useSelector(state => state.watchlistRoth);
  const dispatch = useDispatch();

  const code = fund.fund;

  const watched = watchlist.find(item => item.code === code);
  const Heart = watched ? HeartFilled : HeartOutlined;
  const handleClickHeart = e => {
    e.preventDefault();
    e.stopPropagation();
    if (watched) {
      removeFromWatchlist(code)
        .then(() => {
          dispatch(removeTicker({ code }));
        })
        .catch(err => message.error(err.message));
    } else {
      addToWatchlist(code)
        .then(() => {
          dispatch(
            addTicker({
              code,
              decile: fund.decile,
              total_score: fund.total_score,
            })
          );
        })
        .catch(err => message.error(err.message));
    }
  };

  const watchedRoth = watchlistRoth.find(item => item.code === code);
  const addItem = (e, listName = '401K') => {
    e.preventDefault();
    e.stopPropagation();
    addToWatchlist(code, listName).then(() => {
      const payload = { _id: fund._id, code: code, name: fund.name, category: fund.category, decile: fund.decile, total_score: fund.total_score };
      dispatch(
        (listName == '401K') ? addTicker(payload)
          : (listName == 'ROTH') ? addTickerRoth(payload)
            : addTicker(payload)
      )
    }).catch(err => message.error(err.message));
  }

  const removeItem = (e, listName = '401K') => {
    e.preventDefault();
    e.stopPropagation();
    removeFromWatchlist(code, listName).then(() => {
      const payload = { code };
      dispatch(
        (listName == '401K') ? removeTicker(payload)
          : (listName == 'ROTH') ? removeTickerRoth(payload)
            : addTicker(payload)
      )
    }).catch(err => message.error(err.message));
  }

  return onboard ? (
    <span>
      {disableLike ? null : (
        <Heart style={{ marginRight: 5 }} onClick={handleClickHeart} />
      )}
      <Emphasize
        content={fund.fund}
        word={emphasize}
      />--
      <Emphasize
        content={fund.name}
        word={emphasize}
      />
    </span>
  ) : (
    <>
      <Popover
        overlayStyle={{ zIndex: 2000 }}
        placement="rightTop"
        key={fund.fund}
        content={getContent(fund)}
      >
        <span>
          <Emphasize
            content={fund.fund}
            word={emphasize}
          />--
          <Emphasize
            content={fund.name}
            word={emphasize}
          />&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Popover >
      <span>
        {
          watched ? (<Button icon={<HeartFilled />} shape="round" size="small" onClick={(e) => removeItem(e, '401K')}>Remove from watchlist 401K</Button>)
            : (<Button icon={<HeartOutlined />} shape="round" size="small" onClick={(e) => addItem(e, '401K')}>Add to watchlist 401K</Button>)
        }&nbsp;&nbsp;
        {
          watchedRoth ? (<Button icon={<HeartFilled />} shape="round" size="small" onClick={(e) => removeItem(e, 'ROTH')}>Remove from watchlist ROTH</Button>)
            : (<Button icon={<HeartOutlined />} shape="round" size="small" onClick={(e) => addItem(e, 'ROTH')}>Add to watchlist ROTH</Button>)
        }
      </span>
    </>
  );
};
const SearchBox = ({ onSelect, disableLike = false, onboard = false }) => {
  // search result only exist in local component
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSelect = item => {
    const selected = options.find(op => op.value === item);
    onSelect(selected.value);
  };
  const handleSearch = value => {
    const searchFunc = onboard ? searchFundsDuringOnboard : searchFunds;
    if (value) {
      setLoading(true);
      searchFunc({ keyword: value })
        .then(response => {
          const data = response.data;
          const { funds } = data;
          setOptions(
            funds.map(f => {
              return {
                value: f.fund,
                label: (
                  <SearchResultItem
                    onboard={onboard}
                    disableLike={disableLike}
                    fund={f}
                    emphasize={value}
                  />
                ),
              };
            })
          );
        })
        .finally(() => setLoading(false));
    } else {
      setOptions([]);
    }
  };
  return (
    <AutoComplete
      style={{ alignSelf: 'stretch', width: '100%', verticalAlign: 'middle', marginTop: '-18px' }}
      dropdownMatchSelectWidth={false}
      options={options}
      onSelect={handleSelect}
      onSearch={handleSearch}
    >
      <Search
        allowClear
        placeholder="Type a fund code, or name"
        size="middle"
        loading={loading}
      />
    </AutoComplete>
  );
};

export default SearchBox;
