// page for fund advance search
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, message, Button, Tag, Row, Col } from 'antd';
import { useHistory } from 'react-router';
import { fetchFundsTableData } from '../../service';
import { uniq, intersection } from 'lodash/array';
import FundCompare from '../Dashboard/fundCompare';
import FundDetailPopup from '../FundDetailPopup';

const FundResearch = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openFundsCompare, setOpenFundsCompare] = useState(false);
  const [fundDetail, setFundDetail] = useState({ show: false });
  const history = useHistory();

  const codeClick = (text, record, index) => {
    setFundDetail({ code: text, show: true });
  }

  const columns = [
    {
      title: 'Code',
      dataIndex: 'fund',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Link bounds={0} onClick={(e) => { return codeClick(text, record, index); }}><font style={{ fontSize: 12, color: 'blue' }}>{text}</font></Link>;
      },
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Decile',
      dataIndex: 'decile',
      sorter: true,
    },
    {
      title: 'Score',
      dataIndex: 'total_score',
      sorter: true,
    },
    {
      title: '1 Year Return',
      dataIndex: 'ytd',
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => (
        <span>
          {record.ytd}
          {record.days_since_split < 400 && (
            <sub>
              <font color="blue"> S:{record.days_since_split} days</font>
            </sub>
          )}
        </span>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };
  const fetchData = (params = {}) => {
    setLoading(true);
    fetchFundsTableData({ ...params })
      .then(({ data }) => {
        const { funds, count } = data;
        setData(funds);
        setPagination({ ...params.pagination, total: count });
      })
      .finally(() => setLoading(false));
  };
  const onSelectChange = rowKeys => {
    const updateRowKeys =
      rowKeys.length > selectedRowKeys.length
        ? uniq([...rowKeys, ...selectedRowKeys])
        : intersection(rowKeys, selectedRowKeys);
    if (updateRowKeys.length > 5) {
      message.destroy();
      message.warning('You can only select no more than 5 funds to compare');
      return;
    } else {
      setSelectedRowKeys(updateRowKeys);
    }
  };
  const handleDeselectFund = f => () => {
    setSelectedRowKeys(selectedRowKeys.filter(k => k !== f));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleClickCompare = () => {
    if (selectedRowKeys.length > 0) {
      setOpenFundsCompare(true);
    }
  };
  useEffect(() => {
    fetchData({ pagination });
  }, []);
  return (
    <>
      <Row gutter={[32, 8]} justify="center">
        <Col flex={'auto'}>&nbsp;</Col>
      </Row>
      <Row justify='center'>
        <Col flex={'1300px'} >
          <Button onClick={handleClickCompare} type="primary" size="large">
            compare
          </Button>
          <span style={{ marginLeft: 8, marginRight: 8 }}>
            {selectedRowKeys.length > 0
              ? `Selected ${selectedRowKeys.length} funds`
              : null}
          </span>
          <span style={{ marginTop: 10, marginBottom: 10 }}>
            {selectedRowKeys.map(f => (
              <Tag onClose={handleDeselectFund(f)} color={"#2db7f5"} closable key={f}>
                {f}
              </Tag>
            ))}
          </span>
          <Table
            onRow={record => {
              return {
                onClick: () => {
                  history.push(`?selectedFund=${record.fund}`);
                },
              };
            }}
            rowSelection={rowSelection}
            columns={columns}
            rowKey={record => record.fund}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Col>
        {openFundsCompare ? (
          <FundCompare
            selectedFunds={selectedRowKeys}
            handleClose={() => setOpenFundsCompare(false)}
          />
        ) : null}
      </Row>
      <FundDetailPopup
        show={fundDetail?.show}
        listName={''}
        fundCode={fundDetail?.code}
        onCancel={(e) => setFundDetail({ show: false })}
      />
    </>
  );
};

export default FundResearch;
