import React, { useState } from 'react';
import { Rose } from '@ant-design/charts';

const MarketCap = (props) => {
    const { fund, correlation, series, width, height } = props;
    const { color, colorField, tooltip, label } = props;
    const { startAngle, endAngle, xAxis } = props;

    const data = series.map(i => {
        return {
            cap: i.cap,
            value: correlation[i.key] && (correlation[i.key]['percentage'] | 0),
        }
    });

    var dataFixed = [];
    data.forEach(i => { dataFixed.push(i) });

    return (
        <Rose
            height={height}
            width={width}
            data={data}
            startAngle={startAngle ?? (-90 * Math.PI / 180)}
            endAngle={endAngle ?? (270 * Math.PI / 180)}
            xAxis={xAxis ?? { grid: { closed: true, alignTick: false } }}
            xField='cap'
            yField='value'
            seriesField='cap'
            sectorStyle={{ lineWidth: 1, stroke: "black", strokeOpacity: 0.5 }}
            radius={0.9}
            legend={false}
            autoFit={true}
            label={false}
            color={color}
            colorField={colorField}
            tooltip={tooltip ?? { formatter: (data) => { return { name: data.cap, value: Number(data.value).toFixed(2) + '%' } } }}
        />);
}

export default MarketCap;