import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { steps } from '../../utils/Constants';
import { Card, Button, Typography, Steps, Col, Row, Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardInfo, updateUserProfileByField } from '../../service';
import { addOnBoardInfo } from '../../redux/reducers/onboardInfo';
import utilities from '../../utils/utilities';
import { message } from 'antd';
import { URLS } from '../../utils/Constants';
import { useQuery } from '../../utils/hooks';
import './index.css';

const { Text, Title } = Typography;
const { Step } = Steps;
const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />

// eslint-disable-next-line react/display-name
const CardFormWrapper = React.forwardRef((props, ref) => {
    return <props.content refInstance={ref} profile={props.profile} onFinish={props.onFinish} />;
});

// eslint-disable-next-line react/display-name
const Onboard = (props) => {
    const history = useHistory();
    const query = useQuery();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const formRef = useRef();
    const [onboardInfo, setOnboardInfo] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        message.destroy();
        message.info('Welcome back. Please complete your registration.');
        getOnboardInfo().then(({ data }) => {
            const { profile } = data;
            const { onboard_step } = profile;
            onboard_step && setCurrentIndex(onboard_step);
            setOnboardInfo(profile);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (currentIndex && currentIndex >= steps.length) {
            message.success('Onboarding complete! Now redirecting to dashboard');
//            history.push(URLS.ROOT);
            history.push(URLS.DASHBOARD6);
        }
    }, [currentIndex]);

    const submitField = (onboarded = false) => {
        const _ref = formRef.current;
        _ref.validateFields().then(values => {
            setLoading(true);
            const fieldsAndValues = utilities.changeFieldsAndValues(values);

            dispatch(addOnBoardInfo(fieldsAndValues));

            const [field, value] = Object.entries(fieldsAndValues)[0];
            updateUserProfileByField({ field, value, step: currentIndex, onboarded }).then((response) => {
                setLoading(false);
                if (response.data.success) {
                    if (currentIndex < steps.length - 1) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        goDashboard();
                    }
                }
            }).catch((e) => {
                setLoading(false);
                message.destroy();
                message.error(e);
            })
        }).catch(errorInfo => {
        })

    }

    const onNext = (e) => {
        //working with backend
        submitField();
    }
    const onPrevious = (e) => {
        currentIndex > 0 && setCurrentIndex(currentIndex - 1);
    }

    const onSubmit = (e) => {
        submitField(true);
        /*
        const onboarded = true;
        const _ref = formRef.current;

        _ref.validateFields().then(values => {
            setLoading(true);

        }).catch(errorInfo => {

        })
        const fieldsAndValues = utilities.changeFieldsAndValues(values);
        const [field, value] = Object.entries(fieldsAndValues)[0];

        updateUserProfileByField({ field, value, step: currentIndex, onboarded }).then((response) => {
            if (response.data.success) {
                setTimeout(() => {
                    message.success('Onboarding complete! Now redirecting to dashboard');
                    history.push(URLS.ROOT);
                }, 500);
            }
        }).catch((e) => {
            setLoading(false);
            message.destroy();
            message.error(e.response.data.message);
        })
    */
    }

    const goDashboard = () => {
        message.success('Onboarding complete! Now redirecting to dashboard');
        setTimeout(() => {
//            history.push(URLS.ROOT);
            history.push(URLS.DASHBOARD6);
        }, 500);
    };
    const currentStep = steps[currentIndex];


    return (
        <div className="onboard-card-container">
            <Card style={{ width: 1000, boxShadow: '3px 5px 10px' }} title={'Complete your personal profile to get better service.'} >
                <Row className="onboard-card-row">
                    <Col span={10} push={1}>
                        <Steps direction="vertical" current={currentIndex} className="onboard-card-steps">
                            {
                                steps.map((step, i) => (
                                    <Step key={i} title={step.title} description={step.description} />
                                ))
                            }
                        </Steps>
                    </Col>
                    <Col span={10} push={3}>
                        <Row justify='left'>
                            <Col flex={'auto'} align='left'>
                                {
                                    <Spin indicator={antIcon} spinning={loading} className="onboard-card-loading" >
                                        <Title level={4}>
                                            <Text style={{ paddingLeft: '10px' }} >
                                                {currentStep.description}
                                            </Text>
                                        </Title>
                                        <CardFormWrapper content={currentStep.content} ref={formRef} profile={onboardInfo} />
                                    </Spin>
                                }
                            </Col>
                        </Row>
                        {loading ? <></> : (
                            <Row>
                                <Col span={6} justify='start'>
                                    {
                                        (currentIndex > 0)
                                            ? (<Button block type='primary' onClick={onPrevious}>Previous</Button>)
                                            : (<></>)
                                    }
                                </Col>
                                <Col flex={'auto'}>&nbsp;</Col>
                                <Col span={6} justify='end'>
                                    {
                                        (currentIndex == steps.length - 1)
                                            ? (<Button block type='primary' onClick={onSubmit}>Finish</Button>)
                                            : (<Button block type='primary' onClick={onNext}>Next</Button>)
                                    }
                                </Col>
                            </Row>)}
                    </Col>
                </Row>
            </Card >
        </div >
    )
}

export default Onboard;