// fund popup with the details
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, Line } from '@ant-design/charts';
import { CloseOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Modal, Button, Spin, Row, Col, Statistic, Typography } from 'antd';
import { addToWatchlist, removeFromWatchlist, fetchFundByCode } from '../../service';
import { addTicker, removeTicker } from '../../redux/reducers/watchlist';
import { addTickerRoth, removeTickerRoth } from '../../redux/reducers/watchlistRoth';
import Favourite from '../Watchlist/Favourite';
import Draggable from 'react-draggable';

const { Text, Title } = Typography;

const PopupTitle = ({ fund, listName }, { handleClose }) => {
  const watchlist = useSelector(listName == 'ROTH' ? (state => state.watchlistRoth) : (state => state.watchlist));
  const dispatch = useDispatch();
  const [, updateState] = useState();

  const watched = watchlist.find(item => item.code === fund.fund);
  const Heart = watched ? HeartFilled : HeartOutlined;

  const forceUpdate = useCallback(() => updateState({}), []);

  const handleClickHeart = e => {
    e.preventDefault();
    e.stopPropagation();
    if (watched) {
      removeFromWatchlist(fund.fund, listName).then(() => {
        if (listName == 'ROTH')
          dispatch(removeTickerRoth({ code: fund.fund }));
        else {
          dispatch(removeTicker({ code: fund.fund }));
        }
      })
      forceUpdate();
    } else {
      addToWatchlist(fund.fund, listName).then(() => {
        if (listName == 'ROTH')
          dispatch(
            addTicker({
              code: fund.fund,
              decile: fund.decile,
              total_score: fund.total_score,
            })
          );
        else
          dispatch(
            addTickerRoth({
              code: fund.fund,
              decile: fund.decile,
              total_score: fund.total_score,
            })
          );
      });
      forceUpdate();
    }
  };
  const title = `${fund.fund}--${fund.name}`;
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col >
          <Title level={4}>{fund.name}(<Text>{fund.fund}</Text>)</Title>
        </Col>
        <Col >
          <Favourite fund={fund} listName="401K" /> &nbsp; &nbsp;
        </Col>
        <Col>
          <Favourite fund={fund} listName="ROTH" />
        </Col>
      </Row>
    </div >
  );
};

const FundDetailPopup = ({ fundCode, listName, show, onCancel }) => {
  if (!fundCode) return null;
  const history = useHistory();
  const location = useLocation();
  const [fundDetail, setFundDetail] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    activeDrags: 0,
    deltaPosition: { x: 0, y: 0 },
    controlledPosition: { x: -400, y: 200 },
  });

  const draggleRef = useRef();

  useEffect(() => {
    fetchFundByCode({ code: fundCode }).then(res => {
      const { detail, trend, signal } = res.data;
      setFundDetail({ detail, trend, signal });
    });
  }, []);


  const onStart = () => {
    setModalInfo({ activeDrags: modalInfo.activeDrags + 1 });
  }

  const onStop = () => {
    setModalInfo({ activeDrags: modalInfo.activeDrags - 1 });
  }

  const handleClose = (e) => {
    onCancel && onCancel(e);
  };

  const getTop10HoldingsData = () => {
    const { top10_holding } = fundDetail.detail;
    const data = top10_holding.map(h => ({
      code: h.code,
      weight: parseFloat(h.Weight.trim()),
    }));
    return {
      data: data,
      xField: 'weight',
      yField: 'code',
      seriesField: 'code',
      legend: { position: 'right' },
      autoFit: true,
      tooltip: {
        formatter: datum => {
          return { name: datum.code, value: datum.weight + '%' };
        },
      },
    };
  };
  const getReturnHistory = () => {
    const data = fundDetail.trend.data;
    return {
      data,
      xField: 'date',
      yField: 'return',
      seriesField: 'code',
      smooth: true,
      tooltip: {
        formatter: datum => {
          return { name: datum.code, value: datum.return + '%' };
        },
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v, ' %');
          },
        },
      },
      /*
      animation: {
        appear: {
          animation: 'path-in',
          duration: 3000,
        },
      },
    */
    };
  };
  const getSignalStyle = signalStr => {
    if (signalStr === 'BUY') {
      return { color: '#3f8600' };
    } else if (signalStr === 'SELL') {
      return { color: '#cf1322' };
    } else {
      return { color: '#FAAD14' };
    }
  };

  const getPriceMonthChange = () => {
    if (!fundDetail) return null;
    //    const lastMonthMean = fundDetail.detail.ytd_info.price_mean_last_month;
    //    const currentPrice = fundDetail.detail.ytd_info.current_price;
    const lastMonthMean = fundDetail.detail.ytd_info.price_1month_ago;
    const currentPrice = fundDetail.detail.latest_price;
    const change = currentPrice - lastMonthMean;
    const changePercentage = change / lastMonthMean;
    return {
      change: `${(changePercentage * 100).toFixed(2)}`,
      color: change > 0 ? '#3f8600' : '#cf1322',
    };
  };

  const monthlyChange = getPriceMonthChange();



  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      visible
      title={!fundDetail ? fundCode : <PopupTitle fund={fundDetail.detail} listName={listName} handleClose={handleClose} />}
      onOk={handleClose}
      onCancel={handleClose}
      width={1200}
      okText={"OK"}
      zIndex={9100}
      closable
      destroyOnClose
      modalRender={modal => {
        return (
          <Draggable
            onStart={(event, uiData) => onStart(event, uiData)}
            onStop={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )
      }}
    >
      {fundDetail && fundDetail.signal ? (
        <>
          <Row gutter={2} >
            <Col span={6}>
              <Statistic
                title="Price"
                value={fundDetail.detail ? fundDetail.detail.latest_price : "N/A"}
                prefix="$"
              />
              <Statistic title="Decile(1-11)" value={fundDetail.detail ? fundDetail.detail.decile : "N/A"} />
              <Statistic
                title="Price Monthly Change"
                value={monthlyChange.change}
                suffix="%"
                valueStyle={{ color: monthlyChange.color }}
              />
              <Statistic
                title="One Year Return"
                value={fundDetail.detail ? fundDetail.detail.ytd : "N/A"}
                suffix="%"
                valueStyle={{ color: fundDetail.detail?.ytd ? (fundDetail.detail.ytd >= 0 ? '#3f8600' : '#cf1322') : '#ddd' }}
              />
              {fundDetail.detail && (fundDetail.detail.days_since_split < 400) && (
                <span>
                  <font color="blue" size="1">
                    S:{fundDetail.detail.days_since_split} days
                  </font>
                </span>
              )}
            </Col>
            <Col span={6}>
              <Statistic
                title={fundDetail.signal && fundDetail.signal[0] ? fundDetail.signal[0].date : "no signal"}
                value={fundDetail.signal && fundDetail.signal[0] && fundDetail.signal[0].signal ? fundDetail.signal[0].signal.toUpperCase() : "no signal"}
                valueStyle={getSignalStyle(
                  fundDetail.signal[0] && fundDetail.signal[0].signal ?
                    fundDetail.signal[0].signal.toUpperCase() : "NO SIGNAL"
                )}
              />
              <Statistic
                title={fundDetail.signal && fundDetail.signal[1] ? fundDetail.signal[1].date : "no signal"}
                value={fundDetail.signal && fundDetail.signal[1] ? fundDetail.signal[1].signal.toUpperCase() : "no signal"}
                valueStyle={getSignalStyle(
                  fundDetail.signal[1] && fundDetail.signal[1].signal ?
                    fundDetail.signal[1].signal.toUpperCase() : "NO SIGNAL"
                )}
              />
              <Statistic
                title={fundDetail.signal && fundDetail.signal[2] ? fundDetail.signal[2].date : "no signal"}
                value={fundDetail.signal && fundDetail.signal[2] ? fundDetail.signal[2].signal.toUpperCase() : "no signal"}
                valueStyle={getSignalStyle(
                  fundDetail.signal[2] && fundDetail.signal[2].signal ?
                    fundDetail.signal[2].signal.toUpperCase() : "NO SIGNAL"
                )}
              />
            </Col>
            <Col span={12}>
              <Text type="secondary">Top 10 holdings</Text>
              <Bar style={{ marginTop: 5, height: '240px' }} {...getTop10HoldingsData()} />
            </Col>
          </Row>
          <Row >
            <Col span={24} >
              <Text type="secondary">Return history</Text>
              <Line style={{ marginTop: 5 }} {...getReturnHistory()} />
            </Col>
          </Row>
        </>
      ) : (
        <div style={{ width: "100%", textAlign: "center" }} tip="loading..."> <Spin size="large" /></div >
      )}
    </Modal>)
};

export default FundDetailPopup;
