import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Typography, Button, Descriptions, List, Spin, Modal } from 'antd';
import { CheckOutlined, CloseOutlined, GlobalOutlined } from '@ant-design/icons';
import { getSubscriptionPlans } from '../../service';
import ButtonSubscription from './ButtonSubscription';
//import { SubscriptionData } from './testData';

const { Title } = Typography;
const { confirm } = Modal;

const FeatureIcon = ({ type }) => {
    if (type == 'G') return <GlobalOutlined />;
    if (type == 'R') return <CheckOutlined style={{ color: "green" }} />;
    if (type == 'C') return <CloseOutlined style={{ color: "red" }} />;
    return <></>;
};

const SubscriptionSelection = () => {
    const user = useSelector(store => store.user);
    const subscription = useSelector(store => store.subscription) || {code: ''};
//    const subscription = user.subscription || {code: ''};
	/*
    console.info('----SubscriptionSelection----');
    console.info('--subscription--');
    console.info(subscription);
    */
    const [selected, setSelected] = useState(subscription.code || '');
    const [plans, setSubscriptionPlans] = useState([]);

    useEffect(() => {
        /*
              fetchSubscription()
              .then( res=>{

              });
          */
        getSubscriptionPlans().then(response => {
            const { plans } = response.data;
            setSubscriptionPlans(plans);
        });
    }, []);

    useEffect(()=>{
        setSelected(subscription.code);
    }, [subscription])

    const handleSelect = (index, plan) => {
        if (index == selected) return;
        confirm({
            title: 'Confirm to set plan',
            content: (<>Are you sure to change your plan to <font color="red"><bold>{plan.title}</bold></font>?</>),
            onOK: () => {
                setSelected(index);
            }
        });
    }
    return (
        plans && plans.length ?
            (<Row gutter={16} style={{ marginTop: '20px' }}>
                {
                    //SubscriptionData.map((data, index) =>
                    plans?.map((data, index) =>
                    (<Col span={12} key={index} >
                        < Card hoverable style={{ height: '100%', borderColor: selected === data.code ? '#40a9ff' : '#f0f0f0' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Title level={2}>{data.title}</Title>
                                <Descriptions>
                                    <Descriptions.Item>{data.description}</Descriptions.Item>
                                </Descriptions>
                                <Title level={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ fontSize: '20px' }}>$</span>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {data.price}&nbsp;
                                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', opacity: '0.6', fontSize: '10px' }}>
                                            <span>USD/</span>
                                            <span>mo</span>
                                        </span>
                                    </span>
                                </Title>
                                {/*}(
                                    <Button onClick={() => handleSelect(index, data)} className='' type='primary' style={{ width: '99%' }} size='large'>
                                    {selected === data.code ? 'Extended' : 'Upgrade'}
                                </Button >
                                ){*/}
                                { 
                                ( selected == data.code ) 
                                ? ( <Title level={3} style={{ fonSize: '10px' }}>This is your current plan.</Title>)
                                : ( 
                                    <>
                                    <Title level={3} style={{ fonSize: '10px' }}>Click to subscribe this plan.</Title>
                                    <div> <ButtonSubscription planID={data.pp_plan_id} code={data.code} /> </div>
                                    </>
                                  )
                                }
                                <Title level={5} style={{ marginTop: '40px', textAlign: 'left' }}>Key Benefits:</Title>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <List>
                                    {
                                        data.features.map((features, index) => (
                                            <List.Item key={index}>
                                                <FeatureIcon type={features.icon} /> &nbsp; &nbsp;
                                                {features.content}
                                            </List.Item>
                                        ))
                                    }
                                </List>
                            </div>
                        </Card>
                    </Col >)
                    )
                }
            </Row >)
            : (<Row gutter={16} justify='center' style={{ width: '100%', marginTop: '20px' }}>
                <Col span={2} justify={'center'}>
                    <Spin spinning={true} size={'large'} tip='Loading plans...' />
                </Col>
            </Row>)
    )
}

export default SubscriptionSelection;