const MARKET_SIGNALS = [
    {
        key: 'SP500',
        title: 'SP500',
        collection: 'signal_SPY',
        dataIndex: 'spy',
    },
    {
        key: 'SP500',
        title: 'Dow Jones',
        collection: 'signal_DIA',
        dataIndex: 'dia',
    },
    {
        key: 'Nasdaq',
        title: 'Nasdaq100',
        collection: 'signal_QQQ',
        dataIndex: 'qqq',
    },
    {
        key: 'MidCap',
        title: 'MidCap',
        collection: 'signal_MID',
        dataIndex: 'mid',
    },
    {
        key: 'R2000',
        title: 'R2000',
        collection: 'signal_IWM',
        dataIndex: 'iwm',
    },

    {
        key: 'material',
        title: 'Material',
        collection: 'signal_XLB',
        dataIndex: 'xlb',
    },
    {
        key: 'energy',
        title: 'Energy',
        collection: 'signal_XLE',
        dataIndex: 'xle',
    },
    {
        key: 'finance',
        title: 'Finance',
        collection: 'signal_XLF',
        dataIndex: 'xlf',
    },
    {
        key: 'industrial',
        title: 'Industrial',
        collection: 'signal_XLI',
        dataIndex: 'xli',
    },
    {
        key: 'info_tech',
        title: 'Info Tech',
        collection: 'signal_XLK',
        dataIndex: 'xlk',
    },
    {
        key: 'consumer_staple',
        title: 'Consumer Staple',
        collection: 'signal_XLP',
        dataIndex: 'xlp',
    },
    {
        key: 'real_estate',
        title: 'Real Estate',
        collection: 'signal_XLRE',
        dataIndex: 'xlre',
    },
    {
        key: 'utility',
        title: 'Utility',
        collection: 'signal_XLU',
        dataIndex: 'xlu',
    },
    {
        key: 'healthcare',
        title: 'Healthcare',
        collection: 'signal_XLV',
        dataIndex: 'xlv',
    },
    {
        key: 'consumer_discret',
        title: 'Consumer Discret',
        collection: 'signal_XLY',
        dataIndex: 'xly',
    },
    {
        key: 'metal_mining',
        title: 'Metal Mining',
        collection: 'signal_XME',
        dataIndex: 'xme',
    },
    {
        key: 'oil',
        title: 'Oil Gas Exp & Prod',
        collection: 'signal_XOP',
        dataIndex: 'xop',
    },
    {
        key: 'retail',
        title: 'Retail',
        collection: 'signal_XRT',
        dataIndex: 'xrt',
    },
    {
        key: 'telecom_service',
        title: 'Telecom Service',
        collection: 'signal_XTL',
        dataIndex: 'xtl',
    },

];


export default MARKET_SIGNALS;