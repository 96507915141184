import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const ServerError = () => {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Link to="/login">Back to login</Link>}
    />
  );
};

export default ServerError;
