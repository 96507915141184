
export default (state = {}, action) => {
    switch (action.type) {
        case 'ACTIVATE_401K': {
            return { ...state, 'ACTIVE_WATCHLIST': '401K' };
        }
        case 'ACTIVATE_ROTH': {
            return { ...state, 'ACTIVE_WATCHLIST': 'ROTH' };
        }
        case 'ACTIVATE_ROTH': {
            return { ...state, 'ACTIVE_WATCHLIST': 'ROTH' };
        }
    }
    return state;
};