export const checkSubscription = (subscription) => {
    console.info("----checkSubscription----");
    console.info('--subscription--');
    console.info(subscription);
    if ( !subscription ) return false;
    const { code } = subscription;
    if ( !code ) return false;
    // TODO: check subscription code for plan.

    return true;
}