import React from 'react';
import { Layout, Typography } from 'antd';

import './style.css';

const { Title } = Typography;
const { Header, Content } = Layout;

const SubscriptionLayout = ( props ) => {
	const { children } = props;

	return (
		<Layout className="site-layout">
			<Header className="site-layout-background" style = {{padding: '40px 0'}}>
				<Title level = { 2 }>ACCOUNT AND BILLING</Title>
			</Header>
      		<Content style={{ marginTop: '40px' }}>
        		<div className="site-layout-background">
          			{ children }
        		</div>
      		</Content>
		</Layout>
	)
}

export default SubscriptionLayout;