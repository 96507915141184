import { G2, Line } from '@ant-design/charts';
import React from 'react';

const SignalLine = (props) => {
    const { data, width, height } = props;

    G2.registerShape('point', 'signal-point', {
        draw(cfg, container) {
            const signal = cfg.data.Signal;
            const fillColor = (signal == 'buy') ? 'green' : (signal == 'sell') ? 'red' : (signal == 'hold') ? '#ff7f0e' : 'white';
            const point = { x: cfg.x, y: cfg.y };
            const group = container.addGroup();
            group.addShape('circle', {
                name: 'outer-point',
                attrs: {
                    x: point.x,
                    y: point.y,
                    stroke: 'red',
                    strokeOpacity: 0,
                    fill: fillColor,
                    opacity: 1,
                    r: 2,
                },
            });
            return group;
        }
    });

    const getYAxisConfig = () => {
        var conf = {};
        var min = 100000;
        var max = -100000;
        for (var i in data) {
            data[i].Close = data[i].adj_close;
            data[i].Signal = data[i].signal;
            if (data[i].adj_close < min) min = data[i].adj_close;
            else if (data[i].adj_close > max) max = data[i].adj_close;
        }
        if (min != 100000) conf['min'] = min;
        if (max != -100000) conf['max'] = max;
        conf['label'] = {
            formatter: (text, item, index) => {
                return Number(String(Number(text).toFixed(2)));
            }
        }
        return conf;
    }

    return (
        <Line
            width={width}
            height={height}
            xField='date'
            yField='Close'
            yAxis={getYAxisConfig()}
            data={data}
            legend={false}
            point={{ shape: 'signal-point' }}
            lineStyle={{ r: 0.5, opacity: 0.3, color: 'grey' }}
            tooltip={{
                fields: ['Close', 'Signal'],
            }}
        />)
};

export default SignalLine;