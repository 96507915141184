import React from 'react';
import { Layout, Row, Col, Card, Typography, Alert, Affix } from 'antd';
import BackgroundImg from '../assets/images/background01.jpg';
import Img from '../assets/images/logo.png'
import { registerBehavior } from '@antv/g6-core';
const { Title } = Typography;
const { Content, Header, Footer } = Layout;
const SimpleLayout = ({ children }) => {
  return (
    <>
      <Layout style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
        <Header style={{ height: '82px', background: '#eee', boxShadow: '3px 6px 18px #444', overflow: 'hidden' }}>
          <Title style={{ padding: '20px 50px', }}>
            <img src={Img} width={'60px'} height={'60px'} />
            &nbsp;&nbsp;&nbsp;&nbsp;Welcome to SQVest for 401K Fund Platform
          </Title>
        </Header>
        <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: -1 }}>
          <img src={BackgroundImg} style={{ width: '100%', height: '100%', opacity: 0.55 }} />
        </div >
        <Content style={{ padding: '50px 50px', height: '900px', backgroundColor: 'rgba(255,255,255,0)' }}>
          {children}
        </Content>
        <Affix offsetBottom={0}>
          <Footer style={{ padding: '24, 12', backgroundColor: "#bbb", textAlign: "center", boxShadow: "0 -4px 12px  #333" }}>
            Privacy, Cookies, Security & Legal | Notice of Data Collection | General Terms of Use | Online Access Agreement | Careers | Sitemap
            <br />
            &copy; 2021 - 2024 Squirrel Capitals Inc., All Rights Reserved.
          </Footer>
        </Affix>
      </Layout>
    </>
  );
};

export default SimpleLayout;
