import React, { useRef, useState, useImperativeHandle, useEffect, } from 'react';
import { Form, Divider, Spin, InputNumber, Input, Radio, Tag } from 'antd';
import { uniq, intersection } from 'lodash/array';
import SearchBox from '../searchBox';

export const JobDescription = (props) => {
    const formRef = useRef();
    const [form] = Form.useForm();
    const { profile } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        form.setFieldsValue(profile);
        setLoading(false);
    }, [form]);
    useImperativeHandle(props.refInstance, () => ({
        getFieldsValue: formRef.current.getFieldsValue,
        validateFields: formRef.current.validateFields,
    }));

    return (
        <Form name="basic" form={form} ref={formRef} labelCol={{ span: 8 }} wrapperCol={{ span: 11 }} style={{ marginTop: '30px' }} >
            <Form.Item label="Job" name="job" rules={[{ required: true, message: 'Please input your job.' }]}>
                <Input />
            </Form.Item>
        </Form>
    )
}

export const AgeDescription = (props) => {
    const formRef = useRef();
    const [form] = Form.useForm();
    const { profile } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        form.setFieldsValue(profile);
        setLoading(false);
    }, [form]);
    useImperativeHandle(props.refInstance, () => ({
        getFieldsValue: formRef.current.getFieldsValue,
        validateFields: formRef.current.validateFields,
    }));
    return (
        <Form form={form} ref={formRef} labelCol={{ span: 10 }} wrapperCol={{ span: 15 }}
            style={{ marginTop: '30px' }}>
            <Form.Item label="Age" name="age" rules={[{ required: true, type: 'number', min: 18, max: 70, range: 'Age must be between ${min} and ${max}' },]}>
                <InputNumber />
            </Form.Item>
        </Form >
    )
}

export const RetireAgeDescription = (props) => {
    const formRef = useRef();
    const [form] = Form.useForm();
    const { profile, } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        form.setFieldsValue(profile);
        setLoading(false);
    }, [form]);

    useImperativeHandle(props.refInstance, () => ({
        getFieldsValue: form.getFieldsValue,
        validateFields: form.validateFields,
    }));

    return (
        <Spin spinning={loading}>
            <Form form={form} ref={formRef} labelCol={{ span: 8 }} wrapperCol={{ span: 18 }}
                style={{ marginTop: '30px' }}
            >
                <Form.Item label="Retirement Age" name="retirement_age" rules={[{ required: true, type: 'number', min: 18, max: 70, message: 'Age must be between ${min} and ${max}' },]}>
                    <InputNumber />
                </Form.Item>
            </Form>
        </Spin >
    )
}

export const AssetAllocation = (props) => {
    const formRef = useRef();
    const [form] = Form.useForm();
    const { profile } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        form.setFieldsValue(profile);
        setLoading(false);
    }, [form]);
    useImperativeHandle(props.refInstance, () => ({
        getFieldsValue: formRef.current.getFieldsValue,
        validateFields: formRef.current.validateFields,
    }));

    return (
        <Form form={form} ref={formRef} style={{ marginTop: '30px' }} >
            <Form.Item label="Stock Percentage" name='stock_alloc' rules={[{ required: true }]}>
                <InputNumber min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
            </Form.Item>
            <Form.Item label="Bond Percentage" name='bond_alloc' rules={[{ required: true }]}>
                <InputNumber min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
            </Form.Item>
        </Form>
    )
}

export const AnnualContribution = (props) => {
    const formRef = useRef();
    const [form] = Form.useForm();
    const { profile } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        form.setFieldsValue(profile);
        setLoading(false);
    }, [form]);
    useImperativeHandle(props.refInstance, () => ({
        getFieldsValue: formRef.current.getFieldsValue,
        validateFields: formRef.current.validateFields,
    }));
    return (
        <Form ref={formRef} labelCol={{ span: 10 }} wrapperCol={{ span: 11 }} style={{ marginTop: '30px' }} >
            <Form.Item label="Annual Contribution" name='annual_contribution' rules={[{ required: true, type: 'number', min: 0, range: 'The minimum value of annual constribution is ${min}' },]}>
                <InputNumber />
            </Form.Item>
        </Form>
    )
}

export const InvestmentStyle = (props) => {
    const formRef = useRef();
    const [form] = Form.useForm();
    const { profile } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        form.setFieldsValue(profile);
        setLoading(false);
    }, [form]);
    useImperativeHandle(props.refInstance, () => ({
        getFieldsValue: formRef.current.getFieldsValue,
        validateFields: formRef.current.validateFields,
    }));
    return (
        <Form form={form} ref={formRef} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} style={{ marginTop: '30px' }} >
            <Form.Item
                name="goal"
                label="Investment style"
                rules={[
                    {
                        required: true,
                        message: 'Please pick an item!',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value="ll">Low Risk / Low Return</Radio>
                    <Radio value="aa">Average Risk / Average Return</Radio>
                    <Radio value="hh">High Risk / High Return</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
}

export const FundCandidates = (props) => {
    const formRef = useRef();
    const [funds, setFunds] = useState({ available_funds: [] });
    useImperativeHandle(props.refInstance, () => ({
        validateFields: () => {
            return new Promise((resolve, reject) => {
                resolve(funds);
            })
        },
        getFieldsValue: () => { return funds; }
    }));
    const handleClose = code => () => {
        const restFunds = funds.available_funds.filter(f => f !== code);
        setFunds({ available_funds: restFunds });
        //onChange(restFunds);
    };
    const handleSelect = selected => {
        const newFunds = uniq([...funds.available_funds, selected]);
        setFunds({ available_funds: newFunds });
        //onChange(newFunds);
    };
    return (
        <div>
            <Divider>Search funds</Divider>
            <SearchBox disableLike onSelect={handleSelect} onboard />
            <Divider>Fund candidates</Divider>
            <div>
                {funds?.available_funds.map(f => (
                    <Tag color={'#2db7f5'} onClose={handleClose(f)} closable key={f}>
                        {f}
                    </Tag>
                ))}
            </div>
            <Divider></Divider>
        </div >
    );
}