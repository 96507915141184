export const setSubscriptions = (subscriptions) => {
  return {
    type: 'SET_SUBSCRIPTIONS',
    payload: subscriptions,
  };
};

export default (state = [], action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTIONS': {
      return action.payload || [];
    }
  }
  return state;
};
