import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import { fetchDailyTip } from '../../service';
import { setDailyTip } from '../../redux/reducers/dailytip';
const { Title, Text } = Typography;

const DailyTip = (props) => {
    /*
    const hint = {
        date: '2022/3/20',
        text: '今日半仓stock。',
    }
    */

    const hint = useSelector(store => store.dailyTip) || {};

    const dispatch = useDispatch();
    useEffect(() => {
        fetchDailyTip()
            .then(res => {
                const { dailyTip, ...rest } = res.data;
                dispatch(setDailyTip({ dailyTip }));
            })
            .catch(err => {
                //                history.push(URLS.LOGIN);
                console.log(err);
            });
    }, []);

    //    return (<div>{hint.date}</div> - <div>{hint.text}</div>);
    return (
        <>
            <Row>
                <Col flex='auto'> <Title level={5}>{hint.date}</Title></Col>
            </Row>
            <Row>
                <Col span={5}> <Title level={5}>帮主指示：</Title></Col>
                <Col flex='auto'> <Text strong >{hint.notice}</Text></Col>
            </Row>
        </>
    );
}

export default DailyTip;