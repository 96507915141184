//stock, bond allocation, current YTD based on allocation and YTD trend line compared to SPY
import React, { useState } from 'react';
import { Line } from '@ant-design/charts';
import { Row, Col, Button, Select, Affix, Tabs, Statistic, Typography, Spin } from 'antd';
//import { Line } from '@antv/g2plot';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFundsData } from '../../service';
import { addFundsData } from '../../redux/reducers/user';
import { LINE_COLOR, LINE_COLOR_2 } from '../../constants/colors';
import Watchlists from '../Watchlists';

const Benchmark = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);
  const flag = useSelector(store => store.flag);
  const watchlist = useSelector(store => store.watchlist);
  const watchlistRoth = useSelector(store => store.watchlistRoth);
  const [activeWatchlist, setActiveWatchlist] = useState('401K');
  const [perfIndexes, setPerfIndexes] = useState([]);
  const [dateRange, setDateRange] = useState(3); // time period set to 3 month by default.
  const [compareIndexes, setCompareIndexes] = useState([]);

  const checkFundsInWatchlist = () => {
    const funds = user.fundsData.map(f => f.code);
    var ret = [];
    watchlist.forEach(w => {
      if (funds.indexOf(w.code) < 0) {
        ret.push(w.code);
      }
    })
    watchlistRoth.forEach(w => {
      if (funds.indexOf(w.code) < 0) {
        ret.push(w.code);
      }
    })
    return ret;
  }

  React.useEffect(() => {
    if (user != undefined && user.fundsData != undefined && watchlist != undefined) {
      const missingFunds = checkFundsInWatchlist();
      if (missingFunds.length > 0) {
        fetchFundsData(missingFunds).then((res) => {
          dispatch(addFundsData(res.data.fundsData));
        });
      }
    }
    if (flag && flag['ACTIVE_WATCHLIST'] && activeWatchlist != flag['ACTIVE_WATCHLIST']) setActiveWatchlist(flag['ACTIVE_WATCHLIST']);
  }, [watchlist, watchlistRoth, flag]);


  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth() + 1;
  const mCur = thisYear * 12 + thisMonth;

  const checkInRange = (day) => {
    if (!day) return false;
    const theYear = Number(day.substr(0, 4));
    if (dateRange == -1) {
      // YTD
      return thisYear == theYear;
    }
    // not YTD, dateRange indicate month count from now
    const theMonth = Number(day.substr(5, 2));
    return mCur - (theYear * 12 + theMonth) <= dateRange;
  }

  const dateToValue = (d) => {
    const year = Number(d.substr(0, 4));
    const month = Number(d.substr(5, 2));
    const day = Number(d.substr(8, 2));
    return year * 10000 + month * 100 + day;
  }

  const getOverviewConfig = () => {
    if (user == null) return {};
    const { performanceData = [] } = user;
    const { fundsData = [] } = user;
    const { indexData = [] } = user;
    const fixedReturn = performanceData ? performanceData.map(p => ({
      ...p,
      return: parseFloat(p.return.toFixed(2)),
    })) : [];
    var funds = [];
    var fixedFunds = [];
    var curCode = undefined;
    var curClose = -1.0;
    const theWatchlist = activeWatchlist == '401K' ? watchlist : watchlistRoth;
    const fundsCount = perfIndexes.length == 0 ? theWatchlist.length : perfIndexes.length;
    const indexesCount = compareIndexes.length == 0 ? user.indexes.length : compareIndexes.length;
    const lineColor = (fundsCount + indexesCount) <= 10 ? LINE_COLOR : LINE_COLOR_2;
    indexData ? indexData.forEach(index => {
      if (compareIndexes.length > 0 && compareIndexes.indexOf(index.code) < 0) {
        return;
      }
      if (curCode == undefined || curCode != index.code) {
        curCode = index.code;
        curClose = -1.0;
      }
      if (!checkInRange(index.date)) {
        return;
      }
      if (curClose == -1) {
        curClose = index.adj_close;
      }
      const s = {
        code: index.code,
        date: index.date,
        price: index.adj_close,
        roi: Number(((index.adj_close - curClose) * 100 / curClose).toFixed(2)),
      };
      fixedFunds.push(s);
    }) : [];
    fundsData ? fundsData.forEach(fund => {
      if (perfIndexes.length > 0 && perfIndexes.indexOf(fund.code) < 0) {
        return;
      }
      if (theWatchlist.filter(w => w.fund == fund.code).length == 0) {
        return;
      }
      if (curCode == undefined || curCode != fund.code) {
        curCode = fund.code;
        curClose = -1.0;
      }
      for (var d in fund.bt_data[0]) {
        if (!checkInRange(d)) continue;
        if (curClose == -1) {
          curClose = fund.bt_data[0][d].adj_close;
        }
        const s = {
          code: fund.code,
          price: fund.bt_data[0][d].adj_close,
          date: d,
          roi: Number(((fund.bt_data[0][d].adj_close - curClose) * 100 / curClose).toFixed(2)),
        }
        fixedFunds.push(s);
      }
    }) : [];

    fixedFunds.sort((a, b) => {
      /*
      if (a.date == b.date) {
        return a.code > b.code;
      } 
      */
      const yearA = Number(a.date.substr(0, 4));
      const monthA = Number(a.date.substr(5, 2));
      const dayA = Number(a.date.substr(8, 2));

      const yearB = Number(b.date.substr(0, 4));
      const monthB = Number(b.date.substr(5, 2));
      const dayB = Number(b.date.substr(8, 2));
      if (yearA != yearB) return yearA - yearB;
      if (monthA != monthB) return monthA - monthB;
      return dayA - dayB;
    })
    return {
      /*
      data: fixedReturn,
      xField: 'date',
      yField: 'return',
      seriesField: 'code',
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v, ' %');
          },
        },
      },
      tooltip: {
        formatter: datum => {
          console.info('--datum--');
          console.info(datum);
          return { name: datum.code, value: 'BUY/SELL' + ' @ ' + datum.price };
        },
      },
      */
      data: fixedFunds,
      xField: 'date',
      //      yField: 'price',
      yField: 'roi',
      yAxis: {
        label: {
          formatter: (v) => `${v}%`
        },
      },
      seriesField: 'code',
      color: lineColor,
      legend: {
        // layout: 'vertical',
        layout: 'horizontal',
        position: 'right-top',
        flipPage: false,
        maxRow: 4,
        maxWidthRatio: 120,
        minWidthRatio: 80,
        maxWidth: 200,
        itemWidth: 80,
        width: 160,
        minWidth: 80,
      },
      tooltip: {
        formatter: (data) => {

//          console.info(data);
          return { name: data.code, value: String(data.roi) + '%' };
        }
      },
      //smooth: true,
      // @TODO 后续会换一种动画方式
      animation: {
        appear: {
          animation: 'path-in',
          duration: 3000,
        },
      },
      slider: {
        start: 0,
        end: 1,
        height: 30,
      },
    }
  }

  const dateRanges = [
    { key: '-1', name: '-1', title: 'YTD' },
    { key: '1', name: '1', title: '1 Month' },
    { key: '3', name: '3', title: '3 Month' },
    { key: '6', name: '6', title: '6 Month' },
    { key: '12', name: '12', title: '1 Year' },
  ];

  const addIndex = (code) => {
    if (!perfIndexes) {
      setPerfIndexes([code]);
      return;
    }
    if (perfIndexes.indexOf(code) < 0) {
      setPerfIndexes([...perfIndexes, code]);
    }
  }

  const removeIndex = (code) => {
    if (!perfIndexes) {
      setPerfIndexes([]);
      return;
    }
    var i = perfIndexes.indexOf(code);
    if (i >= 0) {
      perfIndexes.splice(i, 1);
      setPerfIndexes(perfIndexes);
    }
  }

  const getWatchListOptions = () => {
    return watchlist.map(function (item, index, arr) {
      var item2 = {};
      item2.key = item._id;
      item2.value = item.fund;
      item2.label = item.fund + ' - ' + item.name;
      return item2;
    })
  }

  const getIndexesOptions = () => {
    const { indexes = [] } = user;
    return indexes.map(function (item, index, arr) {
      var item2 = {};
      item2.key = item.name;
      item2.value = item.code;
      item2.label = item.code + ' - ' + item.title;
      return item2;
    })
  }

  const indexFilter = (value, option) => {
    if (option.label.toUpperCase().indexOf(value.toUpperCase()) != -1) {
      return true;
    }
    return false;
  }

  const indexChange = (target) => {
    target.checked ? addIndex(target.value) : removeIndex(target.value);
  }

  const perfIndexesChange = (value, option) => {
    setPerfIndexes(value);
  }

  const perfIndexFilter = (value, option) => {

    if (option.label.toUpperCase().indexOf(value.toUpperCase()) != -1) {
      return true;
    }
    return false;
  }

  const compareIndexesChange = (value, option) => {
    setCompareIndexes(value);
  }

  var config = getOverviewConfig();

  return user ? (
    <>
      <Row gutter={[20, 20]} justify='center'>
        <Col flex={'50px'}>
          <span style={{ fontWeight: 'bold' }}>
            Funds:&nbsp;&nbsp;
          </span>
        </Col>
        <Col flex={'400px'}>
          <Select
            style={{ width: '90%' }}
            showArrow
            maxTagCount={5}
            maxTagTextLength={6}
            mode="multiple"
            options={getWatchListOptions()}
            onChange={perfIndexesChange}
            filterOption={perfIndexFilter}
            optionLabelProp="fund"
          />
        </Col>
        <Col flex={'150px'}>
          <span style={{ fontWeight: 'bold' }}>
            Compare to Index:&nbsp;&nbsp;
          </span>
        </Col>
        <Col flex={'300px'}>
          <Select
            style={{ width: '90%' }}
            showArrow
            maxTagCount={5}
            maxTagTextLength={6}
            mode="multiple"
            options={getIndexesOptions()}
            onChange={compareIndexesChange}
            filterOption={indexFilter}
            optionLabelProp="code"
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col>&nbsp;</Col>
      </Row>
      <Row gutter={[20, 20]} justify='center'>
        <Col flex={'1250px'}>
          <Line
            data={[]}
            {...config}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24} align='center'>
          <span style={{ fontWeight: 'bold' }}>
            Date Range: &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          {
            dateRanges.map((p) => {
              return (
                (dateRange == p.name) ? <span key={p.name} style={{ fontWeight: 'bold' }}>{p.title}&nbsp;&nbsp;</span>
                  : (<Button key={p.name} type={"link"} size={"small"} onClick={(e) => { setDateRange(p.name) }}>{p.title}</Button>)
              );
            })
          }
        </Col>
      </Row>
    </>
  ) : null;
};
export default Benchmark;
