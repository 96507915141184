import React, { useState, useRef, useEffect } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import './style.css';

const Arrow = ({ type, style, className, onClick }) => {
    const arrowStyle = { fontSize: 24, color: 'blue' };

    return (type == 'left') ? (<CaretLeftOutlined style={arrowStyle} onClick={onClick} />)
        : (type == 'right') ? (<CaretRightOutlined style={arrowStyle} onClick={onClick} />)
            : undefined
};

export default Arrow;