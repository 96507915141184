import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProTable from '@ant-design/pro-table';
import MARKET_SIGNALS from '../../config/marketSignals';
import { SIGNAL_STYLE } from '../../constants/styles';
import { LockOutlined, } from '@ant-design/icons';
import { Tooltip, } from 'antd';
import './watchlist.css';

var columns = [
    {
        key: 'date',
        title: '',
        align: 'center',
        width: 60,
        dataIndex: 'date',
    },
];

const signalText = (text) => {
    return (text == '0') ? 'sell' :
        (text == '1') ? 'hold' :
            (text == '2') ? 'hold' :
                (text == '3') ? 'buy' :
                (text == '-99') ? (<Tooltip title='Need subscribe'><LockOutlined /></Tooltip>):
                    'n/a';
}

const signalOpacity = (index) => {
    const i = index <= 2 ? index : 2;

    return (25 + 25 * (3 - i)) / 100.0;
}

const signalStyle = (text, index) => {
    return {
        ...SIGNAL_STYLE[text],
        opacity: signalOpacity(index),
    };
};

const signalRender = (text, record, index) => {
    return (<div style={signalStyle(text, index)}>{signalText(String(text))}</div>);
}


const getColumns = (signals) => {
    const use_signals = MARKET_SIGNALS.filter(s1 => signals.find(s2 => { return s1.dataIndex == s2 }));
    return columns.concat(
        use_signals.map((signal) =>
        ({
            key: signal.key,
            title: signal.title,
            align: 'center',
            width: 80,
            dataIndex: signal.dataIndex,
            render: signalRender,
        })
        )
    );
}

const tableConfig = {
    toolbar: false,
    toolBarRender: false,
    options: false,
    search: false,
    pagination: false,
    className: 'SignalTable',
};

const _signal_data = [
    {
        key: 1,
        date: '2022.3.20',
        spy: 0,
        dia: 1,
        qqq: 2,
        mid: 3,
        iwm: 0,
    },
    {
        key: 2,
        date: '2022.3.19',
        spy: 0,
        dia: 2,
        qqq: 1,
        mid: 3,
        iwm: 0,
    },
    {
        key: 3,
        date: '2022.3.18',
        spy: 3,
        dia: 1,
        qqq: 0,
        mid: 1,
        iwm: 0,
    },
];

const getSignalData = (marketSignals, signals) => {
    return marketSignals.filter(signal => { return signals.find(s => signal.dataIndex == s.index); })
}

const MarketSignal = (props) => {
    const user = useSelector(store => store.user);
    const { signalData } = useSelector(store => store.user);
    const { signals = [] } = props;
    tableConfig['columns'] = getColumns(signals);
    const [userSignalData, setUserSignalData] = useState(signalData || [])

    useEffect(() => {
        /*
        console.info("----MarketSignal----");
        console.info("--effect--");
        console.info("--user--");
        console.info(user);
        */
        setUserSignalData(user.signalData || []);
    }, [user])

    return (<ProTable
        dataSource={userSignalData}
        {...tableConfig}
    />);

}

export default MarketSignal;