import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Modal, Breadcrumb, Affix, Alert, message, Menu, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SearchBox from '../components/searchBox';
import SettingsButton from '../components/settingsButton';
import FundDetailPopup from '../components/FundDetailPopup';
import Logout from '../components/Logout';
import Img from '../assets/images/logo.png';
import { checkSubscription } from '../utils/userUtils';
import './style.css';
import { MENU_CONFIG2 } from '../config/menu';

const MENU_CONFIG = MENU_CONFIG2;

const { Header, Footer, Content } = Layout;
const DefaultLayout2 = ({ children }) => {
  const user = useSelector(store => store.user);
  const subscription = useSelector(store => store.subscription);
  const [displayFundDetail, setDisplayFundDetail] = useState({ fund: undefined, show: false });
  const [content, setContent] = useState();
  const [showLogout, setShowLogout] = useState(false);

  const changeContent = (target) => {
    /*
    console.info("----defaultLayout2.changeContent----");
    console.info("--subscription--");
    console.info(subscription);
    console.info(Object.keys(subscription));
    console.info(Object.keys(subscription).length);
    console.info(Object.keys(subscription)? "true":"false");
    console.info("--target--");
    console.info(target);
    */

    const isSubscribed = checkSubscription(subscription);
    var item;
//    if ( user && Object.keys(subscription) >= 0 && !isSubscribed ) {
    if ( user && !isSubscribed ) {
      console.info("--user && !isSubscribed--");
      console.info("--user--"); 
      console.info(user); 
      console.info("--isSubscribed--"); 
      console.info(isSubscribed); 
      item = {key: 'subscription'};
    } else {
      item = {key: target.key};
    }
    const m = MENU_CONFIG.filter(i => i.key == item.key);
    if (m == undefined || m.length == 0) return undefined;
    setContent({ key: m[0].key, component: m[0].component });
  }

  useEffect( () => {
    console.info("----defaultLayout2----");
    console.info("--user--");
    console.info(user);
    changeContent('home');

  } , [user] );

  const doLogout = () => {
    Modal.confirm({
      title: 'Conform to logout',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to leave SQVest?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk() {
        setShowLogout(true);
      },
    })
  }

  const showFundDetail = (fund) => {
    setDisplayFundDetail({ fund: fund, show: true });
  }
  const hideFundDetail = (e) => {
    setDisplayFundDetail({ fund: undefined, show: false });
  }

  return showLogout
    ? (<Logout />)
    : (
      <>
        {/*}
        <Affix offsetTop={-10}>
          <Alert
            closable
            showIcon
            type="info"
            message="Layout2"
          />
        </Affix>
        {*/}
        <Layout>
          <Affix offsetTop={2}>
            <Header style={{ backgroundColor: '#eee', padding: '0 0', height: '48px', borderBottom: '2px solid #324D8E', }}>
              <Row gutter={[4, 4]} style={{ width: '100%', height: '46px' }} align={'top'} justify={'left'}>
                <Col flex={'30px'} align={'center'} style={{ height: '46px' }}>&nbsp;</Col>
                <Col flex={'40px'} align={'top'} justify={'center'} style={{ marginTop: '-2px', height: '46px' }}>
                  <img src={Img} width='30' height='30'></img>
                </Col>
                <Col flex={'550px'} style={{ height: '46px' }}>
                  {MENU_CONFIG.length > 0 ? (<Menu mode="horizontal" defaultSelectedKeys={ content?.key } selectedKeys={content?.key || MENU_CONFIG[0].key}theme={"light"} style={{ padding: '10 0 ', backgroundColor: '#eee' }}>
                    {
                      MENU_CONFIG.map((item) => {
                        return item.hide != true
                          ? (
                            <Menu.Item
                              key={item.key}
                              style={{ height: '46px', padding: '10 0' }}
                              onClick={(item, key, path, e) => changeContent(item)}
                            >{item.name}</Menu.Item>
                          )
                          : undefined;;
                      })
                    }
                  </Menu>) : (<></>)}
                </Col>
                <Col flex={'auto'} style={{ height: '46px' }}>
                  <SearchBox onSelect={showFundDetail} />
                </Col>
                <Col flex={'10px'} style={{ height: '46px' }} justify={'center'}>
                </Col>
                <Col flex={'50px'} style={{ height: '46px' }} justify={'center'}>
                  <SettingsButton changeContent={changeContent} doLogout={doLogout} />
                </Col>
              </Row>
            </Header>
          </Affix>
          <Content style={{ padding: '0px 10px', backgroundColor: 'white' }}>
            <Row justify='center' wrap={false}>
              <Col flex={'1 1 auto'}>
                {content && content.component ? content.component : MENU_CONFIG[0].component}
              </Col>
            </Row>
            <FundDetailPopup fundCode={displayFundDetail.fund} show={displayFundDetail.show} onCancel={hideFundDetail} />
          </Content>
        </Layout >
        <Affix offsetBottom={0}>
          <Footer style={{ padding: '24, 12', backgroundColor: "white", textAlign: "center", boxShadow: "0 -2px 12px -1px #888888" }}>
            &copy; 2021 - 2024 Squirrel Capitals Inc., All Rights Reserved.
          </Footer>
        </Affix>
      </>
    );
};

export default DefaultLayout2;
