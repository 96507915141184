import React, { useState } from 'react';
import ProTable from '@ant-design/pro-table';
import MarketCap from './MarketCap';
import Performance from './Performance';
import SignalLine from './SignalLine';
import { getFundData } from '../../utils/fundUtils';

const FundChartTable = (props) => {
    const { fundList, fundsData } = props;

    const getFundCorrelation = (code) => {
        for (var i in fundsData) {
            if (fundsData[i].code == code) {
                return fundsData[i].correlation;
            }
        }
        return {};
    }

    const marketCapRender = (value, record) => {
        return (<MarketCap
            fund={record.fund}
            correlation={getFundCorrelation(record.fund)}
            series={[
                { key: 'MID', cap: 'Mid' },
                { key: 'IWM', cap: 'Small' },
                { key: 'SPY', cap: 'Large' },
            ]}
            width={300}
            height={300}
            colorField={'cap'}
            color={['#ff7f0e', '#2ca02c', '#9467bd']}
        />);
    }

    const marketSectorRender = (value, record) => {
        return (<MarketCap
            fund={record.fund}
            correlation={getFundCorrelation(record.fund)}
            series={[
                { key: 'XLP', cap: 'XLP' },
                { key: 'XLE', cap: 'XLE' },
                { key: 'XLF', cap: 'XLF' },
                { key: 'XLV', cap: 'XLV' },
                { key: 'XLI', cap: 'XLI' },
                { key: 'XLU', cap: 'XLU' },
                { key: 'XLT', cap: 'XLT' },
                { key: 'XLY', cap: 'XLY' },
            ]}
            width={300}
            height={300}
        />);
    }

    const marketSector2Render = (value, record) => {
        return (<MarketCap
            fund={record.fund}
            correlation={getFundCorrelation(record.fund)}
            series={[
                { key: 'XLT', cap: 'Telecom Services' },
                { key: 'XLY', cap: 'Consumer Discret' },
                { key: 'XLP', cap: 'Consumer Staple' },
                { key: 'XLE', cap: 'Energy' },
                { key: 'XLF', cap: 'Finance' },
                { key: 'XLV', cap: 'Helthcare' },
                { key: 'XLI', cap: 'Industrial' },
                { key: 'XLB', cap: 'Material' },
                { key: 'XLRE', cap: 'Real Estate' },
                { key: 'XLK', cap: 'Info Tech' },
                { key: 'XLU', cap: 'Utility' },
                { key: 'XRT', cap: 'Retail' },
                { key: 'XME', cap: 'Metal Mining' },
                { key: 'XOP', cap: 'Oil Gas Exp & Prod' },
            ]}
            width={300}
            height={300}
            xAxis={{
                grid: { closed: true, alignTick: false },
                label: false,
                tickLine: { alignTick: true },
            }}
            tooltip={{ title: '  ', formatter: (data) => { return { name: data.cap ?? '', value: (data.value ?? 0) + '%' }; } }}
        />);
    }

    const performanceRender = (value, record) => {
        return (<Performance
            width={600}
            height={300}
            data={record}
        />);
    }

    const signalRender = (value, record) => {
        return (<SignalLine
            data={getFundData(value, fundsData)}
            width={400}
            height={300}
        />);
    }

    const columns = [
        {
            key: 'code',
            title: 'Fund',
            width: 80,
            dataIndex: 'fund',
        },
        {
            key: 'market_cap',
            title: 'Market Cap',
            width: 140,
            align: 'center',
            dataIndex: 'fund',
            render: marketCapRender,
        },
        {
            key: 'sector',
            title: 'Sector',
            width: 140,
            align: 'center',
            dataIndex: 'fund',
            render: marketSector2Render,
        },
        {
            key: 'signal',
            title: 'Signal',
            align: 'center',
            width: 400,
            dataIndex: 'fund',
            render: signalRender,
        },
    ];

    return fundList ? (
        <ProTable
            columns={columns}
            dataSource={fundList}
            rowKey="fund"
            toolbar={false}
            toolBarRender={false}
            options={false}
            search={false}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ y: '100%' }}
        />) : (<></>)
}

export default FundChartTable;