import React from 'react';
import FundResearch from '../components/FundResearch';
import PersonalProfile from '../components/PersonalProfile';
import Logout from '../components/Logout';
import Dashboard2 from '../components/Dashboard2';
import Dashboard3 from '../components/Dashboard3';
import Dashboard4 from '../components/Dashboard4';
import Dashboard5 from '../components/Dashboard5';
import Dashboard6 from '../components/Dashboard6';
import Dashboard7 from '../components/Dashboard7';

const MENU_CONFIG = [
    {
        key: 'home',
        name: 'Home',
        component: <Dashboard5 />,
    },
    {
        key: 'benchmark',
        name: 'Benchmark',
        component: <Dashboard2 />,
    },
    {
        key: 'decile',
        name: 'Decile',
        component: <Dashboard3 />,
    },
    {
        key: 'signal',
        name: 'Operation Signal',
        component: <Dashboard4 />,
    },
    {
        key: 'fundResearch',
        name: 'Fund Research',
        component: <FundResearch />,
    },
    {
        key: 'settings',
        name: 'Settings',
        component: <PersonalProfile />,
        hide: true,
    },
    {
        key: 'logout',
        name: 'Logout',
        component: <Logout />,
        hide: true,
    },
];

export const MENU_CONFIG2 = [
    {
        key: 'home',
        name: 'Home',
        component: <Dashboard6 />,
    },
    {
        key: 'subscription',
        name: 'Subscription',
        component: <Dashboard7 />,
    },
    {
        key: 'benchmark',
        name: 'Benchmark',
        component: <Dashboard2 />,
        hide: true,
    },
    {
        key: 'decile',
        name: 'Decile',
        component: <Dashboard3 />,
        hide: true,
    },
    {
        key: 'signal',
        name: 'Operation Signal',
        component: <Dashboard4 />,
        hide: true,
    },
    {
        key: 'fundResearch',
        name: 'Fund Research',
        component: <FundResearch />,
    },
    {
        key: 'settings',
        name: 'Settings',
        component: <PersonalProfile />,
        hide: true,
    },
    {
        key: 'logout',
        name: 'Logout',
        component: <Logout />,
        hide: true,
    },
];
export default MENU_CONFIG;