import React from 'react';
import { Row, Col } from 'antd';
import InvestmentOverview3 from './investmentOverview';

const Dashboard3 = () => {
  return (
    <div style={{
      backgroundColor: 'white'
    }}>
      <Row gutter={8}>
        <Col span={24}>
          <InvestmentOverview3 />
        </Col>
      </Row>
    </div >
  );
};

export default Dashboard3;
