import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootContext from './RootContext';
import { useHistory } from 'react-router';
import { getJwtToken, setJwtToken, removeToken } from '../utils/token';
import { fetchDashboardData } from '../service';
import { URLS } from '../utils/Constants';
import { setSubscriptions } from '../redux/reducers/subscriptions';
import { setSubscription } from '../redux/reducers/subscription';
import { setUser } from '../redux/reducers/user';
import { setWatchlist } from '../redux/reducers/watchlist';
import { setWatchlistRoth } from '../redux/reducers/watchlistRoth';

const AppRootControler = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subscription = useSelector(store => store.subscription);
  const user = useSelector(store => store.user);
  const [userSubscription, setUserSubscription] = useState((subscription && subscription.code) ? subscription : {...subscription, code: ''});

  React.useEffect(() => {
    console.info('----appRootController.effect----');
     console.info('effect []');
    fetchDashboardData()
      .then(res => {
        const { watchlist, watchlistRoth, subscription, subscriptions, ...rest } = res.data;
        dispatch(setWatchlist(watchlist.map(w => ({ ...w, code: w.fund }))));
        dispatch(setWatchlistRoth(watchlistRoth.map(w => ({ ...w, code: w.fund }))));
        dispatch(setSubscriptions(subscriptions));
        dispatch(setSubscription(subscription));
//        dispatch(setUser({ userProfile: rest }));
        dispatch(setUser({ userProfile: res.data }));
        /*
        console.info('----appRootController.effect----');
        console.info('--subscription--');
        console.info(subscription);
      */
      })
      .catch(err => {
        history.push(URLS.LOGIN);
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    console.info('----appRootController.effect2----');
    console.info('--subscription--');
    console.info('subscription changed.');
    console.info(subscription);
    if ( !subscription ) return;
    if ( subscription?.code != userSubscription?.code ) 
    {
        /*
        console.info('----appRootController----');
        console.info('--subscription--');
        console.info(subscription);
  	    fetchDashboardData()
	        .then(res => {
	          const { watchlist, watchlistRoth, subscription, subscriptions, ...rest } = res.data;
	          dispatch(setUser({ userProfile: rest }));
	          dispatch(setWatchlist(watchlist.map(w => ({ ...w, code: w.fund }))));
	          dispatch(setWatchlistRoth(watchlistRoth.map(w => ({ ...w, code: w.fund }))));
	          dispatch(setSubscriptions(subscriptions));
	        })
	        .catch(err => {
	          history.push(URLS.LOGIN);
	          console.log(err);
	        });
       */
    }
  }, [subscription]);

  return (
    <RootContext.Provider
      value={{ getToken: getJwtToken, setToken: setJwtToken, removeToken }}
    >
      {children}
    </RootContext.Provider>
  );
};

export default AppRootControler;
