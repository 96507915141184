//stock, bond allocation, current YTD based on allocation and YTD trend line compared to SPY
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Statistic, Typography, Switch } from 'antd';
import { Line, Pie } from '@ant-design/charts';
import Watchlist from './watchlist';

const { Text } = Typography;

const InvestmentOverview = () => {
  const user = useSelector(store => store.user);
  const [isSmooth, setSmooth] = useState(false);
  const getAllocationConfig = () => {
    const { userProfile } = user;
    const { stock_alloc, bond_alloc } = userProfile;
    const data = [
      { type: 'Stock', value: stock_alloc },
      { type: 'Bond', value: bond_alloc },
    ];
    return {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.5,
      label: {
        type: 'inner',
        offset: '-30%',
        content: function content(_ref) {
          var value = _ref.value;
          return `${value}%`;
        },
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [{ type: 'element-active' }],
    };
  };

  const changeSmooth = (checked, e) => {
    setSmooth(checked);
  }

  const getPerformanceConfig = () => {
    const { performanceData = [] } = user;
    const fixedReturn = performanceData.map(p => ({
      ...p,
      return: parseFloat(p.return.toFixed(2)),
    }));
    return {
      data: fixedReturn,
      xField: 'date',
      yField: 'return',
      seriesField: 'code',
      smooth: isSmooth ?? true,
      tooltip: {
        formatter: datum => {
          return { name: datum.code, value: datum.return + '%' };
        },
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v, ' %');
          },
        },
      },
      animation: {
        appear: {
          animation: 'path-in',
          duration: 3000,
        },
      },
    };
  };
  return user ? (
    <Row>
      <Col span={4}>
        <Col>
          <Statistic
            title="Year to date"
            value={user.ytd ? user.ytd.toFixed(2) : 0.0}
            suffix="%"
          />
        </Col>
        <Col>
          <Text type="secondary">Asset allocation</Text>
          <Pie {...getAllocationConfig()} />
        </Col>
      </Col>
      <Col span={12}>
        <Text type="secondary">Overall performance</Text>
        <Line {...getPerformanceConfig()} />
        <span style={{ display: 'none' }}>Smooth: <Switch size="small" checkedChildren="on" unCheckedChildren="off" checked={isSmooth} onChange={changeSmooth}></Switch></span>
      </Col>
      <Col span={8}>
        <Text type="secondary">Watch List</Text>
        <Watchlist />
      </Col>
    </Row>
  ) : null;
};

export default InvestmentOverview;
