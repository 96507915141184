
export const getBaseConfig = (props) => {
    const { listName = '401K' } = props;
    var config = {
        options: false,
        search: false,
        pagination: { hideOnSinglePage: true },
        listName: listName,
        ...props
    };

    return config;
}