import React, { useState } from 'react';
import { Line } from '@ant-design/charts';
import { LINE_COLOR } from '../../constants/colors';

const DecileChart = (props) => {
    const { data = [], lineColor } = props;
    const { smooth, stepType } = props;



    const getData = (d) => {
        var newData = d.map((v) => ({ ...v, decile: 10 - Number(v.decile) }));
        return newData;
    }

    const config = {
        xField: 'date',
        yField: 'decile',
        seriesField: 'code',
        color: lineColor || LINE_COLOR,
        legend: {
            // layout: 'vertical',
            layout: 'horizontal',
            position: 'right-top',
            flipPage: false,
            maxRow: 4,
            maxWidthRatio: 120,
            minWidthRatio: 80,
            maxWidth: 200,
            itemWidth: 80,
            width: 160,
            minWidth: 80,
        },
        yAxis: {
            label: {
                formatter: (v) => { return (10 - Number(v)).toFixed(0); }
            },
        },
        //smooth: true,
        // @TODO 后续会换一种动画方式
        animation: {
            appear: {
                animation: 'path-in',
                duration: 3000,
            },
        },
        tooltip: {
            formatter: (v) => {
                return { name: v.code, value: (10 - Number(v.decile)).toFixed(2) }
            }
        },
        formatter: (v) => { return (10 - Number(v)).toFixed(2); },
    }

    return data && data.length > 0 ? (<Line
        data={getData(data)}
        {...config}
        stepType={stepType}
        smooth={smooth}
    />)
        : (<div />);

}

export default DecileChart;