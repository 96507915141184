export const setSubscription = (sub) => {
  return {
    type: 'SET_SUBSCRIPTION',
    payload: sub,
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTION': {
      return {...state, ...action.payload};
    }
  }
  return state;
};
