// hh, aa, ll trend lines & slider
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';

const { Text } = Typography;

const InvestmentOutlook = () => {
  const user = useSelector(state => state.user);
  const getConfig = () => {
    const config = {
      data: user.userProfile.returns_array,
      xField: 'year',
      yField: 'value',
      seriesField: 'category',
      color: ['#6897a7', '#60d7a7', '#d96d6f'],
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      legend: { position: 'top' },
    };
    return config;
  };
  return user ? (
    <>
      <Text type="secondary">Investment Outlook</Text>
      <Line {...getConfig()} />
    </>
  ) : null;
};

export default InvestmentOutlook;
