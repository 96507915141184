// personal info, refer to user schema in mongodb
// subscription status
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Descriptions,
  Modal,
  Table, Row, Col,
  Tabs,
  Typography,
  InputNumber,
  Input,
  Radio,
} from 'antd';
import { TransactionOutlined, UserOutlined } from '@ant-design/icons';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { setSubscriptions } from '../../redux/reducers/subscriptions';
import axios from 'axios';
import { setUser } from '../../redux/reducers/user';
import { updateUserInfo } from '../../service';

const { TabPane } = Tabs;
const { Title, Paragraph, Text } = Typography;

const GOAL_MAP = {
  aa: 'Average risk/Average return',
  ll: 'Low risk/Low return',
  hh: 'High risk/High return',
};

const columns = [
  { title: 'Start Date', dataIndex: 'start_date', key: 'start_date' },
  { title: 'Expire Date', dataIndex: 'expire_date', key: 'expire_date' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
];

const AMOUNT = 20.0;
const paypalOptions = {
  'client-id':
    'AfVoDyCAKOlQxCwUDS3IhwT7zWmlbE7eIEy2F-tZAs4H70o6TFHcDPvViv-6RFlD-m7Yf-_O3ZmON1yH',
};

const UserInfo = ({ user, editMode, tempUser, setTempUser }) => {
  return (
    <Descriptions title={user ? ('' + user.userProfile.email + '') : undefined}>
      <Descriptions.Item label="Age">
        {!editMode ? (
          user.userProfile.age
        ) : (
          <InputNumber
            value={tempUser.age}
            onChange={value => {
              tempUser.age = value;
              setTempUser({ ...tempUser });
            }}
            min={18}
            max={70}
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Retirement age">
        {!editMode ? (
          user.userProfile.retirement_age
        ) : (
          <InputNumber
            value={tempUser.retirement_age}
            onChange={value => {
              tempUser.retirement_age = value;
              setTempUser({ ...tempUser });
            }}
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Investment Goal">
        {!editMode ? (
          GOAL_MAP[user.userProfile.goal]
        ) : (
          <Radio.Group
            onChange={e => {
              tempUser.goal = e.target.value;
              setTempUser({ ...tempUser });
            }}
            value={tempUser.goal}
          >
            <Radio value="ll">Low risk/Low return</Radio>
            <Radio value="aa">Average risk/Average return</Radio>
            <Radio value="hh">High risk/High return</Radio>
          </Radio.Group>
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Job">
        {!editMode ? (
          user.userProfile.job
        ) : (
          <Input
            value={tempUser.job}
            onChange={e => {
              tempUser.job = e.target.value;
              setTempUser({ ...tempUser });
            }}
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Initial balance">
        {!editMode ? (
          user.userProfile.current_balance
        ) : (
          <InputNumber
            value={tempUser.current_balance}
            onChange={value => {
              tempUser.current_balance = value;
              setTempUser({ ...tempUser });
            }}
            min={0}
          />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Annual contribution">
        {!editMode ? (
          user.userProfile.annual_contribution
        ) : (
          <InputNumber
            value={tempUser.annual_contribution}
            onChange={value => {
              tempUser.annual_contribution = value;
              setTempUser({ ...tempUser });
            }}
            min={0}
          />
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

const Subscriptions = () => {
  const [visible, setVisible] = useState(false);
  const subscriptions = useSelector(state => state.subscriptions);
  const dispatch = useDispatch();

  const createOrder = async (data, actions) => {
    console.log('createOrder:', data);
    let response = await axios.post('/api/v1/subscriptions');
    console.log(response);

    let subscription = response.data.subscription;

    return actions.order.create({
      purchase_units: [
        {
          reference_id: subscription._id,
          amount: {
            value: AMOUNT,
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    console.log('onApprove:', data, actions);
    const details = await actions.order.capture();
    console.log('captured:', details);
    const id = details.purchase_units[0].reference_id;
    console.log('update subscription status: ', id);
    let response = await axios.put(`/api/v1/subscriptions/${id}`, {
      payment: details,
    });
    console.log('update response:', response);
    setVisible(false);

    console.log('refresh subscription list');
    response = await axios.get('/api/v1/subscriptions');
    console.log('refresh result', response);
    dispatch(setSubscriptions(response.data.subscriptions));
  };

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <Title level={5}>Subscriptions</Title>
      <div style={{ paddingBottom: 8 }}>
        <Button type="primary" disabled onClick={() => setVisible(true)}>
          New Subscription
        </Button>
      </div>
      <Table
        dataSource={subscriptions}
        columns={columns}
        rowKey={row => row._id}
        pagination={false}
      />
      <Modal
        title="New Subscription"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Paragraph>New Subscription from YYYY-MM-DD to YYYY-MM-DD</Paragraph>
        <Paragraph>
          Price: <Text strong>{AMOUNT}</Text>
        </Paragraph>
        <PayPalButtons
          style={{ layout: 'vertical' }}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </Modal>
    </PayPalScriptProvider>
  );
};

const PersonalProfile = () => {
  const user = useSelector(store => store.user);
  const [tempUser, setTempUser] = useState({});
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const handleClickUpdateInfoButton = () => {
    setEditMode(true);
  };
  const handleClickSave = () => {
    setSaving(true);
    updateUserInfo(tempUser)
      .then(() => {
        dispatch(setUser({ userProfile: { ...user, userProfile: tempUser } }));
      })
      .finally(() => {
        setSaving(false);
        setEditMode(false);
      });
  };
  useEffect(() => {
    if (user) {
      setTempUser(user.userProfile);
    }
  }, [user]);
  return (
    <Row gutter={[10, 10]} justify='center'>
      <Col flex={'1300px'}>
        <Tabs tabPosition="left">
          <TabPane
            tab={
              <span>
                <UserOutlined />
                Profile
              </span>
            }
            key="userinfo"
          >
            {user && (
              <div>
                <UserInfo
                  user={user}
                  tempUser={tempUser}
                  setTempUser={setTempUser}
                  editMode={editMode}
                />
                {editMode ? (
                  <>
                    <Button
                      type="primary"
                      onClick={handleClickSave}
                      loading={saving}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setEditMode(false);
                        setTempUser(user);
                      }}
                      disabled={saving}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button onClick={handleClickUpdateInfoButton}>
                    Update Profile
                  </Button>
                )}
              </div>
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                <TransactionOutlined />
                Subscription
              </span>
            }
            key="subscription"
          >
            <Subscriptions />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default PersonalProfile;
