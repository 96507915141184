export const addTicker = ({ _id, code, name, category, decile, total_score }) => {
  return {
    type: 'ADD_TICKER',
    payload: { _id, fund: code, code, name, category, decile, total_score },
  };
};

export const setWatchlist = watchlist => {
  return {
    type: 'SET_WATCHLIST',
    payload: watchlist,
  };
};

export const removeTicker = ({ code }) => {
  return {
    type: 'REMOVE_TICKER',
    payload: code,
  };
};

export default (state = [], action) => {
  switch (action.type) {
    case 'ADD_TICKER': {
      return [...state, action.payload];
    }
    case 'REMOVE_TICKER': {
      return state.filter(item => item.code !== action.payload);
    }
    case 'SET_WATCHLIST': {
      return action.payload;
    }
  }
  return state;
};
