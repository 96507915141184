import React from 'react';
import { Button, message, Row, Col, Typography, Popover, Modal } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Decile from './Decile';
import Score from './Score';
import { removeFromWatchlist } from '../../service';
import { removeTicker } from '../../redux/reducers/watchlist';
import { removeTickerRoth } from '../../redux/reducers/watchlistRoth';
import { fundReturnRender } from './fieldRenders';
import { monthEnglish } from '../../constants/labels';

const onRemove = ({ listName, item, dispatch }) => {
    Modal.confirm({
        title: 'Confirm remove from ' + listName,
        content: (<span>Are you sure to remove fund <font color='red'>{item?.fund}</font> from watchlist <b>{listName}</b> ?</span>),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: (close) => {
            removeFromWatchlist(item?.code, listName).then((re) => {
                if (listName == '401K') {
                    dispatch(removeTicker(item));
                } else if (listName == 'ROTH') {
                    dispatch(removeTickerRoth(item));
                }
                if (data.status == 204)
                    message.success('Success');
            })
            Modal.destroyAll();
        },
    });
};

const operationRender = ({ listName = '401K', value, record }, { dispatch }) => {
    return (<>
        <Popover content={"Remove " + value + " from watchlist " + listName + "."}>
            <DeleteOutlined onClick={(e) => onRemove({ listName, item: record, dispatch })} />
        </Popover>
    </>);
}

const codeRender = ({ showFundName = true, value, record }) => {
    return showFundName ? (
        <>
            <Row ><Col span={24}><span style={{ fontSize: 12, align: 'center', fontWeight: 'bold' }}>{value}</span></Col></Row>
        </>
    ) : <span>******</span>
}

const codeSorter = (a, b) => {
    return a.NAME > b.NAME ? 1 : -1;
}

const nameRender = ({ showFundName = true, value, record }) => {
    return showFundName ? (
        <> <Typography.Text ellipsis={true}>{value}</Typography.Text> </>
    ) : <span>******</span>
}

const categoryRender = ({ showFundCategory = true, value, record }) => {
    return showFundCategory ? (
        <> <Typography.Text ellipsis={true}>{value}</Typography.Text> </>
    ) : <span>******</span>
}

const getColumns = ({
    showAction = true,
    showName = true,
    showCategory = true,
    showDecile = false,
    showScore = false,
    showMonthReturn = false,
    groupMonthReturn = false,
    showMonthDecile = false,
    groupMonthDecile = false,
    showRecommendation = false,
    actionConfig = {},
    codeConfig = {},
    nameConfig = {},
    categoryConfig = {},
    decileConfig = {},
    scoreConfig = {},
    monthReturnConfig = {},
    monthDecileConfig = {},
    recommendationConfig = {},
    listName = '401K',
}, {
    dispatch,
}) => {
    var columns = [];

    const getActionColumn = ({ listName }, config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        };

        return [
            {
                key: 'ACTION',
                title: (<font style={fontStyle}>Action</font >),
                //                width: config.width ?? 20,
                width: 20,
                align: 'center',
                dataIndex: 'code',
                render: config?.operationRender ?? ((text, record, index) => {
                    return operationRender({ listName: listName, value: text, record }, { dispatch });
                }),
            },
        ];
    }

    const getCodeColumn = (config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        }

        return [
            {
                key: 'CODE',
                title: (<font style={fontStyle}>Code</font>),
                dataIndex: 'fund',
                width: 50,
                align: 'left',
                onCell: (record, index) => { return { className: 'WatchlistTableCell' } },
                render: config?.render ?? ((text, record, index) => {
                    return codeRender({ showFundName: config.show ?? true, value: text, record })
                }),
                sorter: config?.sorter ?? codeSorter,
                showSorterTooltip: config?.tooltip ?? false,
            },
        ];
    }

    const getNameColumn = (config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        }

        return [
            {
                key: 'Name',
                title: (<font style={fontStyle}>Name</font>),
                dataIndex: 'name',
                width: 200,
                render: config?.render ?? ((text, record, index) => {
                    return nameRender({ showFundName: config.show ?? true, value: text, record });
                }),
                sorter: config?.sorter,
                showSorterTooltip: config?.tooltip ?? false,
            },
        ];
    }

    const getCategoryColumn = (config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        }

        return [
            {
                key: 'Category',
                title: (<font style={fontStyle}>Category</font>),
                dataIndex: 'category',
                width: 200,
                render: config?.render ?? ((text, record, index) => {
                    return categoryRender({ showFundName: config.show ?? false, value: text, record });
                }),
            },
        ];
    }

    const getDecileColumn = (config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        }

        return [
            {
                key: 'Decile',
                title: (<font style={fontStyle}>Decile</font>),
                dataIndex: 'decile',
                width: 60,
                align: 'center',
                render: config?.render ?? ((text, record, idnex) => {
                    return <Decile value={text} />;
                }),
                sorter: config?.sorter ?? ((a, b) => { return a.decile > b.decile ? 1 : -1; }),
                showSorterTooltip: config?.tooltip ?? false,
            },
        ];

    }

    const getScoreColumn = (config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        }

        return [
            {
                key: 'Score',
                title: (<font style={fontStyle}>Score</font>),
                dataIndex: 'total_score',
                width: 60,
                align: 'center',
                render: config?.render ?? ((text, record, idnex) => {
                    return <Score value={text} />;
                }),
                sorter: ((a, b) => {
                    return a.decile > b.decile ? 1 : -1;
                }),
                showSorterTooltip: config?.tooltip ?? false,
            },
        ];

    }

    const getMonthReturnColumns = (config) => {
        const fontStyle = {
            fontWeight: 500,
            fontSize: 8,
            ...config?.fontStyle,
        }
        const monthReturnColumns = [
            { key: 'rtn_month_1', title: '1 month', width: 60, dataIndex: 'rtn_month_1', render: fundReturnRender },
            { key: 'rtn_month_3', title: '3 months', width: 60, dataIndex: 'rtn_month_3', render: fundReturnRender },
            { key: 'rtn_month_6', title: '6 months', width: 60, dataIndex: 'rtn_month_6', render: fundReturnRender },
            { key: 'rtn_month_12', title: '1 year', width: 60, dataIndex: 'rtn_year_1', render: fundReturnRender },
        ];
        const cols = monthReturnColumns.map((col) => {
            return {
                key: col.key,
                title: (<font style={fontStyle}>{col.title} </font>),
                width: col.width,
                dataIndex: col.dataIndex,
                align: 'right',
                render: col.render,
            }
        })
        return groupMonthReturn ? [{ title: 'Month Return', children: cols }] : cols;
    }

    const getMonthDecileColumns = (config) => {
        var c = [];
        const MONTH_COUNT = 6;
        const curDate = new Date();
        var curMonth = curDate.getMonth(); // 0 for Jan.
        curMonth = curMonth == 0 ? 11 : curMonth - 1;
        const pos = c.length;
        for (var i = 1; i <= MONTH_COUNT; i++, curMonth--) {
            if (curMonth < 0) curMonth = 11;
            c.splice(pos, 0,
                {
                    key: 'month_' + i,
                    dataIndex: 'month_' + i,
                    title: (<font style={{ fontWeight: 500, fontSize: 8 }}>{monthEnglish[curMonth]}</font>),
                    width: 40,
                    align: 'right',
                }
            );
        }
        return groupMonthDecile ? [{ title: 'Month Decile', children: c }] : c;
    }

    const getRecommendationColumns = (config = {}) => {
        var c = [];
        return [{
            key: "recommendation",
            dataIndex: "recommend",
            title: "Recommend",
            width: config.width ?? 40,
            align: 'center',
        }];
    }

    if (showAction) {
        columns.push(...getActionColumn({ listName }, actionConfig));
    }
    // always show CODE
    columns.push(...getCodeColumn(codeConfig));
    if (showName) {
        columns.push(...getNameColumn(nameConfig));
    }
    if (showCategory) {
        columns.push(...getCategoryColumn(categoryConfig));
    }
    if (showDecile) {
        columns.push(...getDecileColumn(decileConfig))
    }
    if (showScore) {
        columns.push(...getScoreColumn(scoreConfig))
    }
    if (showMonthReturn) {
        columns.push(...getMonthReturnColumns(monthReturnConfig))
    }
    if (showMonthDecile) {
        columns.push(...getMonthDecileColumns(monthDecileConfig))
    }
    if (showRecommendation) {
        columns.push(...getRecommendationColumns(recommendationConfig))
    }

    return columns;
}

export default getColumns;