import React, { useState, useRef, useEffect } from 'react';
import { Carousel, Row, Col } from 'antd';
import Arrow from './Arrow.js';

/*
export const Arrow = ({ type, style, className, onClick }) => {
    const arrowStyle = { fontSize: 24, color: 'blue' };

    return (type == 'left') ? (<CaretLeftOutlined style={arrowStyle} />)
        : (type == 'right') ? (<CaretRightOutlined style={arrowStyle} />)
            : undefined
};
*/

const BulletinBoard = (props) => {
    const { pages } = props;
    var ref;
    return (
        <div style={{ margin: 0 }}>
            <Row align='middle'>
                <Col span={1} align='right'><Arrow type='left' onClick={(e) => ref.prev()} /></Col>
                <Col span={22}>
                    <Carousel autoplay={'true'} effect={'scrollx'} dots={false} ref={dom => { ref = dom }}>
                        {pages.map((p) => {
                            return (<div key={p.key} >
                                {p.component}
                            </div>)
                        })}
                    </Carousel>
                </Col>
                <Col span={1} align='left'><Arrow type='right' onClick={(e) => ref.next()} /></Col>
            </Row>
        </div>
    );
}

export default BulletinBoard;