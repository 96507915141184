import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { URLS } from '../../utils/Constants';
import { login } from '../../service';
import { setJwtToken } from '../../utils/token';

const LogIn = ({ admin }) => {
  const history = useHistory();
  const [failedField, setFailedField] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const onFinish = values => {
    login(values)
      .then(response => {
        const { success, token, message } = response.data;
        if (success) {
          setJwtToken(token);
//          history.push(admin ? URLS.ADMIN_DASHBOARD : URLS.ROOT);
          history.push(admin ? URLS.ADMIN_DASHBOARD : URLS.DASHBOARD6);
        } else {
          console.error(message);
        }
      })
      .catch(err => {
        const result = err.response.data;
        setFailedField(result.failedField);
        setErrorMessage(result.message);
      });
  };

  const goRegister = () => {
    history.push(URLS.REGISTER);
  }

  const handleFieldChange = () => {
    setFailedField(null);
    setErrorMessage(null);
  };

  return (
    <Form
      onFieldsChange={handleFieldChange}
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Please input your Email!' },
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
        validateStatus={failedField === 'email' ? 'error' : null}
        help={failedField === 'email' ? errorMessage : null}
      >
        <Input
          allowClear
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
        validateStatus={failedField === 'password' ? 'error' : null}
        help={failedField === 'password' ? errorMessage : null}
      >
        <Input
          allowClear
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" block className="login-form-button">
          Log In
        </Button>
      </Form.Item>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <a className="login-form-forgot" href="/recovery"> Forgot password?  </a>
      </div>
      <Divider></Divider>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button size='large' style={{ backgroundColor: '#42b72a', color: 'white' }} onClick={goRegister}>Register Now!</Button>
      </div>
    </Form>
  );
};

export default LogIn;
