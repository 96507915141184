import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message } from 'antd';
import { CloseOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { addToWatchlist, removeFromWatchlist, fetchFundByCode } from '../../service';
import { addTicker, removeTicker } from '../../redux/reducers/watchlist';
import { addTickerRoth, removeTickerRoth } from '../../redux/reducers/watchlistRoth';

const getWatchlistConfig = (name) => {
    if (name == 'ROTH') {
        return {
            watchlist: useSelector(state => state.watchlistRoth),
            AddFund: addTickerRoth,
            RemoveFund: removeTickerRoth,
        }
    } else {
        return {
            watchlist: useSelector(state => state.watchlist),
            AddFund: addTicker,
            RemoveFund: removeTicker,
        }
    }
}

const Favourite = (props) => {
    const { fund, listName = '401K' } = props;
    const dispatch = useDispatch();
    const { watchlist, AddFund, RemoveFund } = getWatchlistConfig(listName);
    const [loading, setLoading] = useState(false);
    const watched = watchlist.find(item => item.code === fund.fund);
    const Heart = watched ? HeartFilled : HeartOutlined;

    const doAdd = (e) => {
        setLoading(true);
        addToWatchlist(fund.fund, listName).then(() => {
            setLoading(false);
            const payload = { _id: fund._id, code: fund.fund, name: fund.name, category: fund.category, decile: fund.decile, total_score: fund.total_score };
            dispatch(AddFund(payload));
        }).catch(err => {
            message.error(err.message);
            setLoading(false);
        });
    }

    const doRemove = (e) => {
        setLoading(true);
        removeFromWatchlist(fund.fund, listName).then(() => {
            setLoading(false);
            const payload = { _id: fund._id, code: fund.fund, name: fund.name, category: fund.category, decile: fund.decile, total_score: fund.total_score };
            dispatch(RemoveFund(payload));
        }).catch(err => {
            message.error(err.message);
            setLoading(false);
        });
    }

    const buttonConfig = {
        type: "default",
        size: "small",
        shape: "round",
        icon: (<Heart />),
        loading: loading,
        onClick: watched ? ((e) => doRemove(e)) : ((e) => doAdd(e)),
    };

    return watched ? (
        <Button {...buttonConfig}>Remove from watchlist {listName}</Button>
    ) : (
        <Button {...buttonConfig}>Add to watchlist {listName}</Button>
    )
};

export default Favourite;