export const setDailyTip = ({ dailyTip }) => {
    return {
        type: 'SET_DAILY_TIP',
        payload: dailyTip,
    };
};

export default (state = null, action) => {
    switch (action.type) {
        case 'SET_DAILY_TIP': {
            return action.payload;
        }
    };
    return state;
};
