import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { PAYPAL_BUTTON_STYLE, PAYPAL_OPTIONS } from '../../config/paypalConfig';
import { Spin } from 'antd';
import { completeSubscriptionOrder, createSubscriptionOrder } from '../../service';
import { setSubscription } from '../../redux/reducers/subscription';

//<PayPalButtons style={{ layout: "horizontal" }} />

const ButtonWrapper = ({ type, code, onOrderApproved, planID }) => {
    const user = useSelector(store => store.user);
    const { userProfile } = user;
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

    const subconf = {
        //        ...(planID ? { plan_id: planID } : PAYPAL_PLANS[code]),
        plan_id: planID,
        //        ...PAYPAL_PLANS[code],
        application_context: {
            brand_name: 'SQVest.com',
        },
        subscriber: {
            email_address: userProfile.email,
        },
        //custom_id: "31415926535",
    };

    return isPending ? (<Spin tip='loading...' />) : (subconf ? (<PayPalButtons
        style={PAYPAL_BUTTON_STYLE}
        createSubscription={(data, actions) => {
            return actions.subscription
                .create(subconf)
                .then((agreementId) => {
                    // Your code here after create the order
                    /*
                    console.info('----createSubscription----');
                    console.info('--subconf--');
                    console.info(subconf);
                    console.info('--agreementId--');
                    console.info(agreementId);
                    */
                    createSubscriptionOrder({ pp_agreement_id: agreementId, pp_plan_id: planID }).then((res)=>{
                        console.info('--res--');
                        console.info(res);
                        const orderId = res.data.result.order_id;
                        return orderId;
                    });
                    return agreementId;
                });
        }}
        onApprove={(data, actions) => {
            /*
            console.info('----approved----');
            console.info('--data--');
            console.info(data);
            */
            onOrderApproved(data);
        }}
    />) : (<div>Unknown Plan {code}</div>));
}

const ButtonSubscription = (props) => {
    const { planID, code } = props;

    const dispatch=useDispatch();
//    console.info('----ButtonSubscription----');
//    console.info('--planID--');
//    console.info(planID);

    const onOrderApproved = (order) => {
        /*
            console.info('----ButtonSubscription.onApprove----');
            console.info('--order--');
            console.info(order);
            */
            completeSubscriptionOrder(order).then((resp) => {
                // refresh dashboard data.
                /*
                console.info('----dispatch----');
                console.info('--code--');
                console.info(code);
                */
                dispatch(setSubscription({code}));
            });
    }
    return (
        <>
            <PayPalScriptProvider options={PAYPAL_OPTIONS}>
                <ButtonWrapper type="subscription" planID={planID} code={code} onOrderApproved={onOrderApproved} showSpinner />
            </PayPalScriptProvider>
        </>
    );
}

export default ButtonSubscription;