import React, { useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { URLS } from '../../utils/Constants';
import { recovery } from '../../service';
import { setJwtToken } from '../../utils/token';



const Recovery = () => {
  const history = useHistory();
  const [failedField, setFailedField] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const toLogin = () => {
    history.push(URLS.LOGIN);
  }
  const onFinish = values => {
    recovery(values)
      .then(response => {
        const { success, message } = response.data;
        if (success) {
          Modal.info({
            title: 'Success',
            content: (<span>
              A reset password Email is sent to your address. Please follow the intruction in the mail to reset your password.
            </span>),
            onOK: toLogin,
          });
        } else {
          console.error(message);
        }
      })
      .catch(err => {
        const result = err.response.data;
        setFailedField(result.failedField);
        setErrorMessage(result.message);
      });
  };

  const handleFieldChange = () => {
    setFailedField(null);
    setErrorMessage(null);
  };

  return (
    <>
      <span>
        Please enter your Email address for recover password.
      </span>
      <Form
        onFieldsChange={handleFieldChange}
        name="recover-password"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your Email!' },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
          validateStatus={failedField === 'email' ? 'error' : null}
          help={failedField === 'email' ? errorMessage : null}
        >
          <Input
            allowClear
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Please enter your Email address"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Reset Password
          </Button>&nbsp;&nbsp;&nbsp;&nbsp;
          <Button type="default" onClick={toLogin}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Recovery;
