import { combineReducers } from 'redux';
import subscriptions from './subscriptions';
import subscription from './subscription';
import flag from './flag';
import user from './user';
import watchlist from './watchlist';
import watchlistRoth from './watchlistRoth';
import dailyTip from './dailytip';

export default combineReducers({ watchlist, watchlistRoth, user, subscription, subscriptions, flag, dailyTip });

