import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Affix, Tabs, Statistic, Typography, Spin, Radio, Divider, Drawer } from 'antd';
import Watchlist from '../Watchlist';
import { CaretLeftFilled, CaretRightFilled, RightOutlined } from '@ant-design/icons';
import OverallPerformance from '../OverallPerformance2';
import FundDetailPopup from '../FundDetailPopup';
import { watchlistKeys } from '../../constants/funds';
import WatchlistTab from '../Watchlists/WatchlistTab';
import BulletinBoard from '../BulletinBoard';
import BULLETIN_PAGES from '../../config/bulletinPages';
import Benchmark from '../Benchmark';
import DecileChart from '../DecileChart';
import OperationSignal from '../OperationSignal';
import DailyTip from '../DailyTip';
import WatchlistDrawer from '../Watchlists/WatchlistDrawer';
import { getDecileData, fixDecileData } from '../../utils/fundUtils';
import { LINE_COLOR, LINE_COLOR_2 } from '../../constants/colors';
import Subscriptions from '../Subscription';
const { Text } = Typography;

const TABS_CONFIG = [
  {
    value: 'overall',
    label: 'Overall',
  },
  {
    value: 'benchmark',
    label: 'Benchmark',
  },
  {
    value: 'decile',
    label: 'Decile',
  },
  {
    value: 'signal',
    label: 'Signal',
  },
];
const TABS_CONFIG_DEFAULT = TABS_CONFIG[0].value;

const Dashboard7 = () => {
  const user = useSelector(store => store.user);
  const dispatch = useDispatch();
 /*
  console.info('----Dashboard7----');
  console.info('--user--');
  console.info(user);
  */
  return user ? (
    <>
      <Row height='40px' justify='center' >
        <Col>&nbsp; </Col>
      </Row>
      <Row justify='center' gutter={[16, 64]}>
        <Col flex="1600px">
          <Subscriptions />
        </Col>
      </Row>
    </>
  ) : (<Spin size='large'><div style={{ width: "100%", height: '600px', textAlign: "center" }} tip="loading...">Loading </div ></Spin>);
};

export default Dashboard7;
