import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, Space, Spin } from 'antd';
import ProTable from '@ant-design/pro-table';
import { BILLINGHISTORY } from './testData';
import moment from 'moment';

const BillingHistory = () => {

    const user = useSelector(store => store.mode);
    const subscription = useSelector(store => store.subscription);
    const subscriptions = subscription.orders || [];
    const history = subscription.history || [];
    const dispatch = useDispatch();
//    const [userSubscriptions, setUserSubscriptions] = useState(subscriptions || []);
    const [userSubscriptions, setUserSubscriptions] = useState(subscriptions || undefined);

    /*
    {
        no: "ABCDEFGHIJKXXX",
        effective_date: "2022-04-07",
        expiration_date: "2023-04-07",
        membership: "planA",
        amount: 79.99,
        status: 0,
        method: "A",
    },
    */
    const getUserSubscriptions = (subs) => {
        return subs.map(s => {
            return {
                no: s.order_id,
                start_date: s.create_time,
                membership: s.pp_plan?.title, //s.payment?.intent,
                description: s.pp_plan?.description, //
                amount: s.pp_plan?.price,
                status: s.status,
                method: "PayPal",
            }
        });
    }

    useEffect(() => {
//        console.info("----billHistory----");
        const subs = getUserSubscriptions(subscriptions);
        /*
        console.info("--subscriptions----");
        console.info(subs);
        */
        setUserSubscriptions(subs);
    }, []);

    useEffect(() => {
//        console.info("----billHistory effect user----");
        const subs = getUserSubscriptions(subscriptions);
        /*
        console.info("--subscriptions----");
        console.info(subs);
        */
        setUserSubscriptions(subs);
    }, [user]);

    const columns = [
        {
            title: 'Invoice',
            dataIndex: 'no',
            align: "center",
            // eslint-disable-next-line react/display-name
//            render: (_, record) => <a>{record.order_id}</a>,
            width: "120px",
            key: '1',
        },
        {
            title: 'Effective Date',
            valueType: 'date',
            dataIndex: 'start_date',
            align: "center",
            sorter: (a, b) => moment(a.start_date) - moment(b.start_date),
            width: "160px",
            key: '2',
        },
        {
            title: 'Membership',
            dataIndex: 'membership',
            align: "center",
            key: '3',
            width: "120px",
            onFilter: true,
            filters: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            align: "left",
            render: (_, record) => record.description ? record.description : '--',
            width: "400px",
            key: '4',
            onFilter: true,
            filters: true,
        },
        {
            title: 'Amount',
            hideInSearch: true,
            dataIndex: 'amount',
            render: (_, record) => record.amount? '$' + record.amount : '--',
            width: "80px",
            align: "right",
            key: '5',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: true,
            hideInSearch: true,
            onFilter: true,
            width: "120px",
            align: "center",
            /*
            valueEnum: {
                0: { text: 'Unpaid', status: 'Error' },
                1: { text: 'Paid', status: 'Success' },
            },
            */
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <Space>
                        <Tag color={record.status == 'COMPLETED' ? 'green' : 'red'}>{record.status == 'COMPLETED' ? 'Paid' : 'Unpaid'}</Tag>
                    </Space>
                )
            },
            key: '6',
        },
        {
            title: 'Payment Method',
            hideInSearch: true,
            dataIndex: 'method',
            valueEnum: {
                A: { text: 'Visa' },
                B: { text: 'Master Card' },
                C: { text: 'Other' },
            },
            align: "center",
            width: "80px",
            filters: true,
            onFilter: true,
            key: '7',
        }
    ];
    /*
            request={(params, sorter, filter) => {
                console.log(params, sorter, filter);
                return Promise.resolve({
                    data: BILLINGHISTORY,
                    success: true,
                });
            }}
    */
    return userSubscriptions  ? (
        <ProTable
            columns={columns}
            toolbar={{ settings: false }}
            pagination={{
                pageSize: 10,
            }}
            dataSource={userSubscriptions}
            tableLayout="fixed"
            cardBordered
            bordered
            search={false}
            rowKey="no"
            style={{ marginTop: '20px' }}
            headerTitle="Billing History"
            dateFormatter="string"
        />
    ) : (<Spin size='large'><div style={{ width: "100%", height: '600px', textAlign: "center" }} tip="loading...">Loading </div ></Spin>);
}

export default BillingHistory;