import React, { useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import ProTable from '@ant-design/pro-table';
import getColumns from './columns';
import { getBaseConfig } from './configs';
import './watchlist.css';

const getConfig = (props) => {
    const config = getBaseConfig(props);
    return config;
}

const SimpleWatchlist = (props) => {
    const { fundList, title } = props;
    const dispatch = useDispatch();

    const config = getConfig(props);
    const columns = getColumns(config, { dispatch });
    const data = fundList;

    return (<ProTable
        columns={columns}
        dataSource={data}
        className={"WatchlistTable"}
        size={'middle'}
        {...config}
    />);
}

export default SimpleWatchlist;