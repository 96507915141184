import React from 'react';
import {
    Form,
    Input,
    Button,
    Card,
    Select
} from 'antd';

import utilities from '../../utils/utilities';

const { Option } = Select;
//const YEARS = utilities.generateYearList();
const YEARS = [2022, 2023, 2024];

const CreditCardForm = () => {
    const onFinish = (value) => {
        console.log(value);
        console.log('value');
    }
    return (
        <Card>
            <Form
                name='Enter New Card Information Below'
                scrollToFirstError
                onFinish={onFinish}
                layout='vertical'
            >
                <Form.Item
                    label='Credit Card Number'
                    name='credit card number'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your credit card number!'
                        },
                        {
                            pattern: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/,
                            message: 'Please input valid credit card number!'
                        }
                    ]}
                    hasFeedback
                >
                    <Input style={{ width: '70%' }} />
                </Form.Item>
                <Form.Item
                    label='Expiration Date'
                    style={{ display: 'inline-block', width: '50%', marginRight: '20px' }}
                >
                    <Form.Item
                        name="month"
                        rules={[{ required: true, message: '' }]}
                        style={{ display: 'inline-block', width: 'calc(40% - 8px)', marginRight: '8px' }}
                    >
                        <Input placeholder="MM" />
                    </Form.Item>
                    <Form.Item
                        name="year"
                        rules={[{ required: true, message: '' }]}
                        style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
                    >
                        <Select
                            placeholder="YYYY"
                        >
                            {
                                YEARS.map((year) => (
                                    <Option key={year} value={year}>{year}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label='CVC'
                    name='cvc'
                    required tooltip='CVC is on the back of your card.'
                    rules={[
                        {
                            required: true,
                            message: ''
                        },
                        {
                            pattern: /^[0-9]{3,4}$/,
                            message: 'Invalid number'
                        }
                    ]}
                    style={{ display: 'inline-block' }}
                >
                    <Input style={{ width: '40%' }} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Update Card</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default CreditCardForm;