import Axios from 'axios';
const qs = require('qs');
Axios.defaults.timeout = 30000;
export const searchFunds = ({ keyword }) => {
  return Axios.get(
    `/api/v1/fund?search=${encodeURIComponent(keyword.trim())}&mode=quick&pageSize=50`,
    { timeout: 180000 },
  );
};
export const searchFundsDuringOnboard = ({ keyword }) => {
  return Axios.get(
    `/api/v1/user/onboard/searchFund?search=${encodeURIComponent(
      keyword.trim()
    )}&mode=quick`
  );
};

export const addToWatchlist = (code, listName = '401K') => {
  return Axios.get('/api/v1/watchlist/add', { params: { code, listName } });
};
export const removeFromWatchlist = (code, listName = '401K') => {
  return Axios.get('/api/v1/watchlist/remove', { params: { code, listName } });
};

export const updateUserProfileByField = ({
  field,
  value,
  step,
  onboarded = false,
}) => {
  return Axios.put('/api/v1/user/onboard', { field, value, step, onboarded });
};

export const fetchFundByCode = ({ code }) => {
  return Axios.get(`/api/v1/fund/${encodeURIComponent(code.trim())}`);
};

export const updateUserInfo = userInfo => {
  return Axios.put('/api/v1/user/profile', userInfo);
};

export const fetchFundsByCodeArray = ({ codes }) => {
  return Axios.get('/api/v1/fundsTrend', {
    params: { codes },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const registerUser = values => {
  return Axios.post('/api/v1/signon', values);
};

export const getOnboardInfo = () => {
  return Axios.get('/api/v1/user/onboard');
};

export const fetchDashboardData = () => {
  //  by default timeout is 60 seconds, in some situation cause timeout.
  //  Axios.defaults.timeout = 180000;
  return Axios.get('/api/v1/user/dashboard', { timeout: 1800000 });
};

export const fetchFundsData = (codes) => {
  return Axios.post('/api/v1/fund/data', codes)
}

export const login = signInObject => {
  return Axios.post('/api/v1/signin', signInObject);
};
export const recovery = (user) => {
  return Axios.post('/api/v1/recovery', user);
}
export const fetchFundsTableData = ({ pagination, sortField, sortOrder }) => {
  return Axios.get('/api/v1/fund', {
    params: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortField,
      sortOrder,
    },
  });
};

export const sendResetEmail = ({ email }) => {
  return Axios.get(
    `/api/v1/resetemail?email=${email.trim()}`
  );
};

export const fetchDailyTip = () => {
  return Axios.get(
    `/api/v1/dailytip`
  );
};

export const getSubscriptionPlans = () => {
  return Axios.get(
    `/api/v1/subscriptions/plan`
  );
};

export const createSubscriptionOrder = (order) => {
  return Axios.post('/api/v1/subscriptions/createOrder', {
    ...order
  });
};

export const completeSubscriptionOrder = (order) => {
  return Axios.post('/api/v1/subscriptions/completeOrder',
    {
      ...order
    });
};