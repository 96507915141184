import React, { useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Link } from 'react-router-dom';
import ProTable from '@ant-design/pro-table';
import getColumns from './columns';
import getData from './data';
import { getBaseConfig } from './configs';
import './watchlist.css';

const getConfig = (props) => {
    const config = {
        showDecile: true,
        showScore: true,
        ...getBaseConfig(props),
        codeConfig: {
            // eslint-disable-next-line react/display-name
            render: (text, record, index) => {
                // eslint-disable-next-line react/display-name
                return <Link bounds={0} onClick={(e) => { return props?.codeConfig?.onClick(text, record, index); }}><font style={{ fontSize: 12, color: 'blue' }}>{text}</font></Link>;
            },
        },
    };
    return config;
}

const NormalWatchlist = (props) => {
    const { fundList, title } = props;
    const dispatch = useDispatch();

    const config = getConfig(props);
    const columns = getColumns(config, { dispatch });
    const data = getData(props, fundList);

    return (<ProTable
        columns={columns}
        dataSource={data}
        size={"middle"}
        className={"WatchlistTableNormal"}
        {...config}
    />);
}

export default NormalWatchlist;