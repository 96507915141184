import React, { useState } from 'react';
import { Mix } from '@ant-design/plots';
import { countFundsReturn } from '../../utils/fundUtils';

const OverallPerformance = (props) => {
    const { fundsData, performanceData, watchlist, } = props;
    const { id } = props;
    const { weightLow = 0, weightHigh = 1 } = props;
    const startDate = performanceData[0]?.date;
    const endDate = performanceData[performanceData.length - 1]?.date;
    const fundsReturn = countFundsReturn({ watchlist, fundsData, startDate, endDate, weightLow, weightHigh });
    const performanceReturn = performanceData.filter((d) => { return d.code == 'SPY' });
    const min1 = Math.min.apply(null, fundsReturn.map(r => r.return[0]));
    const max1 = Math.max.apply(null, fundsReturn.map(r => r.return[1]));
    const min2 = Math.min.apply(null, performanceReturn.map(r => r.return));
    const max2 = Math.max.apply(null, performanceReturn.map(r => r.return));
    const min = min1 < min2 ? min1 : min2;
    const max = max1 > max2 ? max1 : max2;
    const getConfig = () => {
        return {
            appendPadding: 16,
            syncViewPadding: true,
            tooltip: { shared: true, showMarkers: false, showCrosshairs: true, offsetY: -50 },
            slider: {
                start: 0.1,
                end: 0.5
            },
            legend: { layout: 'horizontal', position: 'right' },
            layout: { title: 'Overall Performance' },
            views: [
                {
                    data: fundsReturn,
                    axes: {
                        return: {
                            min: min,
                            max: max,
                        },
                    },
                    meta: {
                        return: {
                            formatter: (v) => { return v.toFixed(2) + '%'; },
                            alias: 'Estimated Performance',
                        },
                    },
                    geometries: [
                        {
                            type: 'area',
                            xField: 'date',
                            yField: 'return',
                            mapping: {},
                        },
                    ],
                },
                {
                    data: performanceReturn,
                    axes: {
                        return: {
                            min: min,
                            max: max,
                        },
                        date: false,
                    },
                    meta: {
                        return: {
                            formatter: (v) => { return v.toFixed(2) + '%'; },
                            alias: 'Reference Return(SPY)',
                        },
                    },
                    geometries: [
                        {
                            type: 'line',
                            xField: 'date',
                            yField: 'return',
                            mapping: {},
                            category: 'code',
                            smooth: true,
                        },
                    ],
                },
            ],
        };
    }

    return (<Mix {...getConfig()} />);
}

export default OverallPerformance;