import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Affix, Tabs, Statistic, Typography, Spin, Radio, Divider, Drawer } from 'antd';
import Watchlist from '../Watchlist';
import { CaretLeftFilled, CaretRightFilled, RightOutlined } from '@ant-design/icons';
import OverallPerformance from '../OverallPerformance2';
import FundDetailPopup from '../FundDetailPopup';
import { watchlistKeys } from '../../constants/funds';
import WatchlistTab from '../Watchlists/WatchlistTab';
import BulletinBoard from '../BulletinBoard';
import BULLETIN_PAGES from '../../config/bulletinPages';
import Benchmark from '../Benchmark';
import DecileChart from '../DecileChart';
import OperationSignal from '../OperationSignal';
import DailyTip from '../DailyTip';
import WatchlistDrawer from '../Watchlists/WatchlistDrawer';
import { getDecileData, fixDecileData } from '../../utils/fundUtils';
import { LINE_COLOR, LINE_COLOR_2 } from '../../constants/colors';
const { Text } = Typography;

const TABS_CONFIG = [
  {
    value: 'overall',
    label: 'Overall',
  },
  {
    value: 'benchmark',
    label: 'Benchmark',
  },
  {
    value: 'decile',
    label: 'Decile',
  },
  /*
  {
    value: 'signal',
    label: 'Signal',
    subscription: ['BA', 'AD'],
  },
*/
];
const TABS_CONFIG_DEFAULT = TABS_CONFIG[0].value;

const Dashboard6 = () => {
  const user = useSelector(store => store.user);
  const flag = useSelector(store => store.flag);
  const { fundsData } = user || { fundsData: [] };
  const watchlist = useSelector(state => state.watchlist);
  const watchlistRoth = useSelector(state => state.watchlistRoth);
  const [activeWatchlist, setActiveWatchlist] = useState(flag['ACTIVE_WATCHLIST'] ?? '401K');
  const [activeCard, setActiveCard] = useState('overall');
  const [fundDetail, setFundDetail] = useState({ show: false });
  const [fullWatchlistVisible, setFullWatchlistVisible] = useState(false);
  const dispatch = useDispatch();


  useEffect(() => {
    if (flag && flag['ACTIVE_WATCHLIST'] && activeWatchlist != flag['ACTIVE_WATCHLIST']) setActiveWatchlist(flag['ACTIVE_WATCHLIST']);
  }, [flag])

  const getWatchlist = (key) => {
    if (key == '401K') return watchlist;
    if (key == 'ROTH') return watchlistRoth;
    return [];
  }


  const changeWatchlist = (key) => {
    setActiveWatchlist(key);
    if (key == '401K') dispatch({ type: 'ACTIVATE_401K' });
    else if (key == 'ROTH') dispatch({ type: 'ACTIVATE_ROTH' });
    else dispatch({ type: 'ACTIVATE_401K' });
  }

  const getActiveWatchlist = () => {
    const ret = getWatchlist(activeWatchlist);
    return ret;
  }

  const getDecileDataFixed = () => {
    const watchlistDecile = getDecileData(getActiveWatchlist(), fundsData);
    const watchlistDecileFixed = fixDecileData(watchlistDecile);
    return watchlistDecileFixed;
  }

  const codeClick = (text, record, index) => {
    setFundDetail({ code: text, show: true });
  }

  const onChange = (e) => {
    setActiveCard(e.target.value);
  }

  const getActiveCard = () => {
    if (activeCard == 'overall')
      return (user.performanceData && user.performanceData.length > 0) ? (
        <>
          <Text type="secondary">Overall Performance</Text>
          <OverallPerformance
            fundsData={user.fundsData}
            performanceData={user.performanceData}
            watchlist={getActiveWatchlist()}
            weightLow={0.2}
            weightHigh={0.8}
            isSmooth={true}
          />
        </>)
        : (<span>Please choose fund to watchlist first.</span>)
    if (activeCard == 'benchmark')
      return (<Benchmark />);
    if (activeCard == 'decile') {
      return (
        <DecileChart
          data={getDecileDataFixed()}
          lineColor={watchlist.length <= 10 ? LINE_COLOR : LINE_COLOR_2}
          smooth={true}
        />
      );
    }
    if (activeCard == 'signal')
      return (<OperationSignal />);
    return undefined;
  }

  return user && getActiveWatchlist() ? (
    <>
      <Row gutter={[32, 24]} height='160px' justify="center" >
        <Col span={'12'} >
          <div style={{ height: '100px', padding: '10px' }}>
            <BulletinBoard pages={BULLETIN_PAGES} />
          </div>
        </Col>
        <Col flex={'20px'}></Col>
        <Col span={'8'}>
          <DailyTip />
        </Col>
      </Row>
      <Row height='40px' justify='center'>
        <Col span={20} >
          <Divider style={{ borderColor: 'black' }} />
        </Col>
      </Row>
      <Row gutter={[0, 8]} justify="center" height={"50px"} >
        <Col flex={'10px'} align='right' style={{ height: '600px', background: '#DDDDDD', boxShadow: '8px 12px 15px #CCCCCC' }}>
          <Row align="middle">
            <Col flex={'10px'} style={{ height: '500px', background: '#DDDDDD' }}>
              <div style={{ position: 'absolute', top: '50%', marginTop: '-100%' }}>
                {/*
                fullWatchlistVisible ?
                  (<CaretLeftFilled sytle={{ fontSize: '20px' }} onClick={(e) => setFullWatchlistVisible(false)} />)
                  : (<CaretRightFilled sytle={{ fontSize: '20px' }} onClick={(e) => setFullWatchlistVisible(true)} />)
                */}
              </div>
            </Col>
          </Row>
        </Col>
        <Col flex={'0px'} style={{ height: '600px' }}>
          <Row>
            <Col style={{ height: '20px' }}></Col>
          </Row>
          <WatchlistDrawer
            visible={fullWatchlistVisible}
            onClose={(e) => setFullWatchlistVisible(false)}
          />
        </Col>
        <Col flex={'300px'} style={{ height: '600px', background: '#FFFFFF', padding: '20px', boxShadow: '8px 12px 15px #CCCCCC' }}>
          <Row>
            <Col style={{ height: '20px' }}></Col>
          </Row>
          <WatchlistTab tabPosition='left'
            open={false}
            openHandle={(e) => setFullWatchlistVisible(true)}
            closeHandle={(e) => setFullWatchlistVisible(false)}
          />
        </Col>
        <Col flex={"30px"} >
        </Col>
        <Col flex={"1200px"}>
          <Row>
            <Col flex={"1100px"}>
              <Radio.Group
                options={TABS_CONFIG}
                optionType="button"
                buttonStyle="solid"
                onChange={onChange}
                value={activeCard || TABS_CONFIG_DEFAULT}
              />
            </Col>
          </Row>
          <Row gutter={[20, 20]} justify='center' style={{ padding: '20px' }}>
            <Col flex={"1100px"}>
              {getActiveCard()}
            </Col>
          </Row>
        </Col>
      </Row>
      <FundDetailPopup
        show={fundDetail?.show}
        listName={activeWatchlist}
        fundCode={fundDetail?.code}
        onCancel={(e) => setFundDetail({ show: false })}
      />
      <div style={{ backgroundColor: 'white' }}>
      </div>
    </>
  ) : (<Spin size='large'><div style={{ width: "100%", height: '600px', textAlign: "center" }} tip="loading...">Loading </div ></Spin>);
};

export default Dashboard6;
