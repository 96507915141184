import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Affix, Tabs, Statistic, Typography, Spin } from 'antd';
import Watchlist from '../Watchlist';
import OverallPerformance from '../OverallPerformance2';
import FundDetailPopup from '../FundDetailPopup';
import { watchlistKeys } from '../../constants/funds';
import './index.css';

const { TabPane } = Tabs;
const { Text } = Typography;

const Dashboard5 = () => {
  const user = useSelector(store => store.user);
  const flag = useSelector(store => store.flag);
  const watchlist = useSelector(state => state.watchlist);
  const watchlistRoth = useSelector(state => state.watchlistRoth);
  const [activeWatchlist, setActiveWatchlist] = useState(flag['ACTIVE_WATCHLIST'] ?? '401K');
  const [fundDetail, setFundDetail] = useState({ show: false });
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveWatchlist(activeWatchlist);
  }, [flag])

  const getWatchlist = (key) => {
    if (key == '401K') return watchlist;
    if (key == 'ROTH') return watchlistRoth;
    return [];
  }

  const getWatchlistCount = (key) => {
    const theWatchlist = getWatchlist(key) ?? [];
    const length = theWatchlist.length;
    if (length == 0) return (<>no funds.</>)
    if (length == 1) return (<>1 fund.</>)
    return (<>{length} funds</>);
  }

  const getTabContent = (key) => {
    return (<>
      <Row wrap={false}
      >
        <Col flex="1 1 200px" >
          <Row><span style={{ fontSize: 20, fontWeight: 'bold' }}>{key}</span></Row>
          <Row><span style={{ fontColor: 'black' }}>{getWatchlistCount(key)}</span></Row>
          <Row> </Row>
        </Col>
        <Col flex="0 1 300px">
          &nbsp;
        </Col>
      </Row >
    </>)
  }

  const changeWatchlist = (key) => {
    setActiveWatchlist(key);
    if (key == '401K') dispatch({ type: 'ACTIVATE_401K' });
    else if (key == 'ROTH') dispatch({ type: 'ACTIVATE_ROTH' });
    else dispatch({ type: 'ACTIVATE_401K' });
  }

  const getActiveWatchlist = () => {
    const ret = getWatchlist(activeWatchlist);
    return ret;
  }

  const codeClick = (text, record, index) => {
    setFundDetail({ code: text, show: true });
  }


  return user && getActiveWatchlist() ? (
    <>
      <Affix offsetTop={48}>
        <Row gutter={[32, 16]} justify="center" >
          <Col flex={"1300px"}>
            <div style={{ backgroundColor: '#fff', margin: '0px -10px', padding: '0px 20px' }}>
              <Tabs
                type='card' tabBarGutter={24} onChange={changeWatchlist}
                tabBarStyle={{ borderBottomWidth: '6px', borderBottomColor: '#fff', marginBottom: '5px' }}
              >
                {watchlistKeys.map((key) =>
                  <TabPane key={key} tab={getTabContent(key)}
                  />
                )}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Affix>
      <Row gutter={[32, 8]} justify="center">
        <Col flex={'auto'}>&nbsp;</Col>
      </Row>
      <Row gutter={[32, 16]} justify="center">
        <Col flex={"500px"}>
          <Watchlist
            headerTitle={(<span style={{ color: '#aaa' }}>Watchlist {activeWatchlist}</span>)}
            type="fasion"
            showName={true}
            showCategory={false}
            listName={activeWatchlist}
            fundList={getActiveWatchlist()}
            codeConfig={{ onClick: codeClick }}
          />
        </Col>
        <Col flex={"800px"}>
          {
            (user.performanceData && user.performanceData.length > 0) ? (
              <>
                <Text type="secondary">Overall Performance</Text>
                <OverallPerformance
                  fundsData={user.fundsData}
                  performanceData={user.performanceData}
                  watchlist={getActiveWatchlist()}
                  weightLow={0.2}
                  weightHigh={0.8}
                  isSmooth={true}
                />
              </>)
              : (<span>Please choose fund to watchlist first.</span>)
          }
        </Col>
      </Row>
      <FundDetailPopup
        show={fundDetail?.show}
        listName={activeWatchlist}
        fundCode={fundDetail?.code}
        onCancel={(e) => setFundDetail({ show: false })}
      />
      <div style={{ backgroundColor: 'white' }}>
      </div>
    </>
  ) : (<Spin size='large'><div style={{ width: "100%", height: '600px', textAlign: "center" }} tip="loading...">Loading </div ></Spin>);
};

export default Dashboard5;
