import { addDay, addYear } from '../../utils/dateUtils';
import { getFundData, countReturn, countYTD2 } from '../../utils/fundUtils';
import { countWatchlistDecile, countWatchlistRecommendationDecile, getRecommendation } from '../../utils/fundUtils';

const countMonthReturn = (watchlist, fundsData) => {
    var newWatchlist = [];

    var endDate = new Date();
    var newWatchlist = [];
    watchlist.forEach(f => {
        var data = {
            ...f,
        }
        if (f.decile > 10) {
            data.decile = 10;
        }
        var fundData = getFundData(f.fund, fundsData);
        for (var i = 1; i <= 12; ++i) {
            var key = 'rtn_month_' + i;
            var startDate = addDay(endDate, -30 * i);
            data[key] = countReturn(f.fund, fundData, startDate, endDate);
        }
        var key = 'rtn_year_1';
        var startDate = addYear(endDate, -1);
        data[key] = countReturn(f.fund, fundData, startDate, endDate);
        key = 'ytd';
        data[key] = countYTD2(f.fund, fundData, endDate);
        newWatchlist.push(data);
    })
    return newWatchlist;
}

const countMonthDecile = (watchlist, fundsData) => {
    return countWatchlistDecile(watchlist, fundsData);
}

const countRecommendation = (watchlist, fundsData) => {
    countWatchlistRecommendationDecile(watchlist, fundsData);
    return getRecommendation(watchlist);
}

const getData = (props, fundList) => {
    var newWatchlist = fundList;
    if (props.showMonthReturn) {
        newWatchlist = countMonthReturn(newWatchlist, props.fundsData);
    }
    if (props.showMonthDecile) {
        newWatchlist = countMonthDecile(newWatchlist, props.fundsData);
    }
    if (props.showRecommendation) {
        newWatchlist = countRecommendation(newWatchlist, props.fundsData);
    }
    return newWatchlist;
}

export default getData;
