import React from 'react';
import axios from 'axios';
import { Form, Checkbox, Input, Button, Alert, Modal } from 'antd';
import { Route, withRouter } from 'react-router-dom';
import { registerUser } from '../../service';
import { setJwtToken } from '../../utils/token';
import { URLS } from '../../utils/Constants';

const forItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8
    }
}
const tailFormItemLayout = {
    wrapperCol: {
        span: 16,
        offset: 8
    },
    labelCol: {
        span: 8
    }
}

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            errorMessage: '',
            error: false,
            agree: false,
            showPolicy: false,
        }
    }

    handleSubmit = (props) => {
        const { email, username, password, confirm } = props;
        //        axios.post(`${baseUrl}`, props).then((response) => {
        var values = {};
        values['email'] = email.trim();
        values['username'] = username.trim();
        values['password'] = password;
        values['confirm'] = confirm;
        const { agree } = this.state;
        const history = this.props.history;
        registerUser(values).then((response) => {
            if (response.data.success) {
                //                window.localStorage.setItem('token', response.data.token);
                setJwtToken(response.data.token);
                //               this.props.history.push(URLS.ON_BOARD);
                history.push(URLS.ON_BOARD);
            } else {
                this.setState({ error: true, errorMessage: 'Register failed.' })
            }
        }).catch((e) => {
            //            this.setState({ error: true, errorMessage: 'Register Failed\n Email has taken!' })
            this.setState({ error: true, errorMessage: e.response.data.message })
        })
    }

    onClickAgree = (e) => {
        this.setState({ agree: e.target.checked });
    }

    onClosePolicy = (e) => {
        this.setState({ showPolicy: false });
    }

    showPolicy = (e) => {
        this.setState({ showPolicy: true });
    }

    render() {
        return (
            <>
                {
                    this.state.error && <Alert style={{ marginBottom: '20px' }} message="Error" type="error" description={this.state.errorMessage} showIcon />
                }
                <Form name='basic' {...forItemLayout} onFinish={this.handleSubmit}>
                    <Form.Item name="email" label="Email" rules={[{ type: "email", message: "The input is not a valid email" }, { required: true, message: 'Please input your email' }]}>
                        <Input placeholder="Please input your name" onChange={this.handleEmailChange} />
                    </Form.Item>
                    <Form.Item name="username" label="Username" rules={[{ required: true, message: "Please input your username" }]}>
                        <Input placeholder="Please input your username" />
                    </Form.Item>
                    <Form.Item name="password" label="Password" hasFeedback rules={[{ required: true, message: "Please input your password" }, { min: 6, message: 'Passowrd must be at least 6 characters!' }]}>
                        <Input.Password placeholder="Please input your password" />
                    </Form.Item>
                    <Form.Item name="confirm" label="Confirm Passowrd" hasFeedback rules={[{ required: true, message: "Please re-input your password" }, ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please confirm your password! The two passwords that you entered do not match!'))
                        }
                    })]}>
                        <Input.Password allowClear placeholder="Please re-input your password" />
                    </Form.Item>
                    <Form.Item name="agree_to_privacy_policy" wrapperCol={{ offset: 8, span: 16 }}>
                        <Checkbox onChange={this.onClickAgree}>Agree to our&nbsp;
                            <a href="/Policy.htm" target='_new'>Privacy and Cookie Policy</a>&nbsp;.
                            You may receive email notifications from us and can opt out any time.</Checkbox>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type='primary' disabled={!this.state.agree} htmlType='submit' size='large' style={{ width: '220px' }} key='submit'>Register</Button>
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default withRouter(Register);