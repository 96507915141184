import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Affix, Tabs, Drawer, Statistic, Typography, Spin } from 'antd';
import { CaretRightFilled, RightOutlined } from '@ant-design/icons';
import { watchlistKeys } from '../../constants/funds';
import Watchlist from '../Watchlist';
import FundDetailPopup from '../FundDetailPopup';
import './drawer.css';
import WatchlistTab from './WatchlistTab';

const WatchlistDrawer = (props) => {
    const { visible } = props;
    const { onClose } = props;
    const user = useSelector(store => store.user);
    const flag = useSelector(store => store.flag);
    const [activeWatchlist, setActiveWatchlist] = useState(flag['ACTIVE_WATCHLIST'] ?? '401K');
    const watchlist = useSelector(state => state.watchlist);
    const watchlistRoth = useSelector(state => state.watchlistRoth);

    const getWatchlist = (key) => {
        if (key == '401K') return watchlist;
        if (key == 'ROTH') return watchlistRoth;
        return [];
    }

    const changeWatchlist = (key) => {
        setActiveWatchlist(key);
        if (key == '401K') dispatch({ type: 'ACTIVATE_401K' });
        else if (key == 'ROTH') dispatch({ type: 'ACTIVATE_ROTH' });
        else dispatch({ type: 'ACTIVATE_401K' });
    }
    const getActiveWatchlist = () => {
        const ret = getWatchlist(activeWatchlist);
        return ret;
    }

    return (
        <Drawer
            destroyOnClose
            handler={false}
            visible={visible}
            mask={false}
            closable={true}
            keyboard
            onClose={(e) => onClose()}
            getContainer={false}
            placement={'left'}
            contentWrapperStyle={{ visible: false }}
            zIndex={visible ? 9000 : -1}
            width={1400}
            style={{ position: 'absolute', height: '200px', width: '1400px', padding: '20px', overflow: 'visible', boxShadow: '8px 12px 15px #DDDDDD' }}
            headerStyle={{ display: 'none' }}
            height={10}
        >
            <Row style={{ height: '480px', width: '1400px', background: '#FFFFFF' }}>
                <Col flex={'auto'} style={{ padding: '20px', background: '#FFFFFF' }}>
                    <WatchlistTab
                        headerTitle={(<span style={{ color: '#aaa' }}>Watchlist {activeWatchlist}</span>)}
                        type="normal"
                        showName={true}
                        showDecile={true}
                        showCategory={true}
                        showMonthDecile={true}
                        groupMonthDecile={true}
                        showMonthReturn={true}
                        groupMonthReturn={true}
                        listName={activeWatchlist}
                        fundList={getActiveWatchlist()}
                        open={true}
                        closeHandle={(e) => onClose()}
                    />
                </Col>
            </Row>
        </Drawer >


    );
}

export default WatchlistDrawer;