import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Line } from '@ant-design/charts';
import { fetchFundsByCodeArray } from '../../service';

const FundCompare = ({ handleClose, selectedFunds }) => {
  const [trendData, setTrendData] = useState([]);
  useEffect(() => {
    fetchFundsByCodeArray({ codes: selectedFunds }).then(res => {
      const { trend } = res.data;
      setTrendData(trend);
    });
  }, [selectedFunds]);
  const getReturnHistory = () => {
    const data = trendData;
    return {
      data,
      xField: 'date',
      yField: 'return',
      seriesField: 'code',
      smooth: true,
      tooltip: {
        formatter: datum => {
          return { name: datum.code, value: datum.return + '%' };
        },
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v, ' %');
          },
        },
      },
      animation: {
        appear: {
          animation: 'path-in',
          duration: 3000,
        },
      },
    };
  };
  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      visible
      title="Compare funds"
      onOk={handleClose}
      onCancel={handleClose}
      width={1200}
    >
      <Line style={{ marginTop: 5 }} {...getReturnHistory()} />
    </Modal>
  );
};

export default FundCompare;
