export const setUser = ({ userProfile }) => {
  return {
    type: 'SET_USER',
    payload: userProfile,
  };
};

export const removeUser = () => {
  return {
    type: 'REMOVE_USER',
    payload: code,
  };
};

export const addFundsData = (data) => {
  return {
    type: 'ADD_FUNDS_DATA',
    payload: data,
  }
}

export default (state = null, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return action.payload;
    }
    case 'REMOVE_USER': {
      return state.filter(item => item.code !== action.payload);
    }
    case 'ADD_FUNDS_DATA': {
      return {
        ...state,
        fundsData: state.fundsData.concat(action.payload)
      };
    }
  }
  return state;
};
