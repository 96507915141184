
const _BUY_STYLE = {
    background: 'red',
};

const _HOLD_STYLE = {
    background: 'yellow',
};

const _SELL_STYLE = {
    background: 'green',
};

export const SIGNAL_STYLE = {
    '0': _BUY_STYLE,
    '1': _HOLD_STYLE,
    '2': _HOLD_STYLE,
    '3': _SELL_STYLE,
};
