import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Affix, Tabs, Statistic, Typography, Spin } from 'antd';
import { watchlistKeys } from '../../constants/funds';
import Watchlist from '../Watchlist';
import FundDetailPopup from '../FundDetailPopup';

const { TabPane } = Tabs;
const { Text } = Typography;

const Watchlists = ({ watchlistProps }) => {
    const user = useSelector(store => store.user);
    const fundsData = user.fundsData;
    const flag = useSelector(store => store.flag);
    const watchlist = useSelector(state => state.watchlist);
    const watchlistRoth = useSelector(state => state.watchlistRoth);
    const [activeWatchlist, setActiveWatchlist] = useState(flag['ACTIVE_WATCHLIST'] ?? '401K');
    const [fundDetail, setFundDetail] = useState({ show: false });
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveWatchlist(activeWatchlist);
    }, [flag])

    const getWatchlist = (key) => {
        if (key == '401K') return watchlist;
        if (key == 'ROTH') return watchlistRoth;
        return [];
    }

    const getWatchlistCount = (key) => {
        const theWatchlist = getWatchlist(key) ?? [];
        const length = theWatchlist.length;
        if (length == 0) return (<>no funds.</>)
        if (length == 1) return (<>1 fund.</>)
        return (<>{length} funds</>);
    }

    const getTabContent = (key) => {
        return (<>
            <Row wrap={false} style={{ padding: '0 0' }}>
                <Col flex="1 1 200px" >
                    <Row><span style={{ fontSize: 20, fontWeight: 'bold' }}>{key}</span></Row>
                    <Row ><span style={{ fontColor: 'black' }}>{getWatchlistCount(key)}</span></Row>
                    <Row> </Row>
                </Col>
                <Col flex="0 1 300px">
                    &nbsp;
                </Col>
            </Row >
        </>)
    }

    const changeWatchlist = (key) => {
        setActiveWatchlist(key);
        if (key == '401K') dispatch({ type: 'ACTIVATE_401K' });
        else if (key == 'ROTH') dispatch({ type: 'ACTIVATE_ROTH' });
        else dispatch({ type: 'ACTIVATE_401K' });
    }

    const getActiveWatchlist = () => {
        const ret = getWatchlist(activeWatchlist);
        return ret;
    }

    const codeClick = (text, record, index) => {
        setFundDetail({ code: text, show: true });
    }

    return user && getActiveWatchlist() ? (
        <>
            <Affix offsetTop={0}>
                <Row justify='center' style={{ backgroundColor: '#fff', margin: '0px -200px 10px -200px', boxShadow: '3px 5px 8px #ddd' }}>
                    <Col flex='1250px'>
                        <Tabs type='card'
                            linestyle={{ padding: '0 0' }}
                            tabBarStyle={{ borderBottomWidth: '6px', borderBottomColor: '#fff', marginBottom: '5px' }}
                            tabBarGutter={24} activeKey={activeWatchlist} onChange={changeWatchlist} >
                            {watchlistKeys.map((key) =>
                                <TabPane key={key} tab={getTabContent(key)}></TabPane>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </Affix>
            <Row gutter={[32, 16]}>
                <Col flex={"1 1 400px"}>
                    <Watchlist
                        type="normal"
                        showName={true}
                        showCategory={true}
                        {...watchlistProps}
                        listName={activeWatchlist}
                        fundList={getActiveWatchlist()}
                        fundsData={fundsData}
                        codeConfig={{ onClick: codeClick }}
                    />
                </Col>
            </Row>
            <FundDetailPopup
                show={fundDetail?.show}
                listName={activeWatchlist}
                fundCode={fundDetail?.code}
                onCancel={(e) => setFundDetail({ show: false })}
            />
            <div style={{ backgroundColor: 'white' }}>
            </div>
        </>
    ) : (<div style={{ width: "100%", textAlign: "center" }} tip="loading..."> <Spin size="large" /></div >);
};



export default Watchlists;