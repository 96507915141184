import React, { useState, useRef } from 'react';
import { Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { removeToken } from '../../utils/token';
import { URLS } from '../../utils/Constants';

const Logout = () => {
  const history = useHistory();
  const timerRef = useRef();
  const timerValue = useRef();
  const [timeout, setTimeout] = useState(10);
  var t = 10;
  React.useEffect(() => {
    removeToken();
    timerValue.current = 10;
    timerRef.current = setInterval(() => {
      if (timerValue.current <= 1) {
        clearInterval(timerRef.current);
        gotoLogin();
      } else {
        timerValue.current = timerValue.current - 1;
        setTimeout(timerValue.current);
      }
    }, 1000);
  }, []);

  const gotoLogin = () => {
    history.push(URLS.LOGIN);
  };
  return (
    <Result
      icon={<SmileOutlined />}
      title="See you soon!"
      extra={
        <Button onClick={gotoLogin} type="primary">
          Back to login ({timeout}s)
        </Button>
      }
    />
  );
};

export default Logout;
