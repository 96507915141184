import React from 'react';
import { Col, Typography, Row, Input, Tooltip, Divider} from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import CreditCardForm from '../creditCardForm';
import utilities from '../../utils/utilities';

import './index.css';

const FAKENUMBER = '2222420000001113';
const EXPIREBY = '02/2022';

const { Title } = Typography;

const PaymentMethod = () => {
    return (
        <>
            <Row style = {{ marginTop: '30px' }}>
				<Col sm = {24} md = {8} lg = {8}>
					<Title level = {5}>Current Payment Method</Title>
				</Col>
                <Col sm = {24} md = {8} lg = {8}>
					<Input
						placeholder={utilities.creditCardNumberFormat(FAKENUMBER)}
						prefix={<CreditCardOutlined/>}
						suffix={
						<Tooltip title="Expires"> 
							{EXPIREBY}
						</Tooltip>
						}
						disabled
					/>
                </Col>
            </Row>
			<Divider style = {{marginBottom: '10px'}}/>
            <Row>
                <Title level = {5}>Update Payment Method</Title>
            </Row>
			<Row>
				<CreditCardForm/>
			</Row>
        </>
    )
}

export default PaymentMethod;