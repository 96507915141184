import { getLastDayOfMonth, addDay, dayCompare, dateToString } from "./dateUtils";

export const getFundData = (code, fundsData) => {
    var fundData = [];
    for (var i in fundsData) {
        if (fundsData[i].code == code) {
            for (var j in fundsData[i].bt_data) {
                for (var key in fundsData[i].bt_data[j]) {
                    fundData.push({
                        date: key,
                        ...fundsData[i].bt_data[j][key]
                    });
                }
            }
        }
    }
    fundData.sort((x, y) => {
        return x.data == y.data ? 0 : x.data > y.data ? 1 : -1;
    });
    return fundData;
}

const getDayString = (d) => {
    if (d == undefined) return "";
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return year + "-" + (month >= 10 ? month : "0" + month) + "-" + (day >= 10 ? day : "0" + day);
}

const findEffectDataOfDay = (data, day, backward = true) => {
    if (data == undefined || data.length == 0) return undefined;
    const length = data.length;
    const d = getDayString(day);
    if (d < data[0].date && backward) {
        return undefined;
    }
    if (d > data[length - 1].date) return data[length - 1];
    var n = 0;
    for (var i = 1; i < length; ++i) {
        if (data[i].date == d) return data[i];
        if (data[i].date > d) {
            if (!backward) return data[i];
            break;
        }
        n = i;
    }
    return data[n];
}

export const countYTD = (code, fundData, end) => {
    var q = findEffectDataOfDay(fundData, end);
    if (q == undefined) return "na";
    return String(q.ytd / 100);
}

export const countYTD2 = (code, fundData, end) => {
    var start = new Date(end.getFullYear(), 0, 1)
    return countReturn(code, fundData, start, end);
}

export const countReturn = (code, fundData, start, end) => {
    if (start > end) return "na";
    var p = findEffectDataOfDay(fundData, start, false);
    if (p == undefined) return "na";
    var q = findEffectDataOfDay(fundData, end);
    if (q == undefined) return "na";
    try {
        const r = ((q.adj_close - p.adj_close) / p.adj_close).toFixed(4);
        return String(r);
    } catch (e) {
        return "na";
    }
}

//  momth: 0-11;
export const countMonthDecile = (code, fundData, year, month) => {
    if (month < 0 || month > 11) return undefined;
    var d = getLastDayOfMonth(year, month);
    var count = 0;
    var s = 0;
    while (count < 5) {
        var p = findEffectDataOfDay(fundData, d);
        if (p == undefined) return undefined;
        var decile = p.decile;
        if (decile > 10) {
            // 99 or 9999.
            decile = 10;
        }
        s += decile;
        count++;
        d = new Date(p.date);
        d = addDay(d, -1);
        if (d.getMonth() != month) return undefined;
    }
    return s / count;
}

// month: 0-11
const countMonthData = (code, fundData, year, month) => {
    var decile = countMonthDecile(code, fundData, year, month);
    if (decile == undefined) return 'na';
    return String(decile.toFixed(2));
}

export const countWatchlistDecile = (watchlist, fundsData) => {
    const today = new Date();
    var newData = watchlist.map(fund => {
        var f = { ...fund };
        var data = getFundData(fund.fund, fundsData);
        var thisYear = today.getFullYear();
        var thisMonth = today.getMonth(); // start form 0
        thisMonth = thisMonth - 1; // last month
        for (var i = 0; i < 12; ++i, thisMonth--) {
            if (thisMonth < 0) {
                thisYear--;
                thisMonth = 11;
            }
            const key = 'month_' + (i + 1);
            f[key] = countMonthData(fund.fund, data, thisYear, thisMonth);
        }
        return f;
    })

    return newData;
}

// change month decile data to string.
export const getDecileData = (watchlist, fundsData) => {
    const watchlistData = countWatchlistDecile(watchlist, fundsData);
    var newData = [];
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1; // from 0 - 11.
    watchlistData.forEach(d => {
        var data = { code: d.code };
        var thatYear = thisYear;
        var thatMonth = thisMonth;
        for (var i = 1; i <= 12; ++i) {
            thatMonth = thisMonth - i;
            if (thatMonth <= 0) {
                thatYear = thisYear - 1;
                thatMonth = thatMonth + 12;
            }
            const key = 'month' + '_' + i;
            const dateString = thatYear + '/' + (thatMonth <= 9 ? '0' : '') + (thatMonth);
            const decile = d[key] == 'na' ? 10 : Number(d[key]);
            newData.push({
                ...data,
                date: dateString,
                decile: decile > 10 ? 10 : decile,
            })
        }
    });
    newData.sort((a, b) => a.date > b.date ? 1 : a.date == b.date ? 0 : -1);
    return newData;
}

export const fixDecileData = (watchlist) => {
    var newData = [];
    watchlist.forEach(d => {
        for (var i = 9; i >= 1; --i) {
            const key = 'month' + '_' + i;
            if (d[key] == 'na') {
                const key1 = 'month' + '_' + (i + 1);
                const key2 = 'month' + '_' + (i + 2);
                const key3 = 'month' + '_' + (i + 3);
                if (d[key1] != 'na' && d[key2] != 'na' && d[key3] != 'na') {
                    d[key] = String(((Number(d[key1]) + Number(d[key2]) + Number(d[key3])) / 3).toFixed(2));
                }
            }
        }
        newData.push(d);
    })

    return newData;
}

const countDecileInRange = (fundData, from, to) => {
    const length = fundData.length;
    if (length < to - 1) return 0;
    const d1 = length - from;
    const d2 = length - to;
    /*
    console.info('--slice--');
    console.info(d2 + ', ' + d1);
    console.info('--count--');
    console.info(d1 - d2);
    console.info('--slice--');
    console.info(fundData.slice(d2, d1));
    */
    return fundData.slice(d2, d1).reduce((sum, data) => { return sum + (data.decile <= 10 ? data.decile : 10); }, 0) / (d1 - d2);
}

export const countWatchlistRecommendationDecile = (watchlist, fundsData) => {
    watchlist.forEach(d => {
        const fundData = getFundData(d.fund, fundsData);
        d['6M'] = countDecileInRange(fundData, 81, 171);
        d['3M'] = countDecileInRange(fundData, 33, 93);
        d['1M'] = countDecileInRange(fundData, 9, 35);
        d['1W'] = countDecileInRange(fundData, 2, 10);
        //        d['1D'] = fundData.length > 0 ? fundData[fundData.length - 1].decile : 0;
        d['1D'] = countDecileInRange(fundData, 0, 2);
    })
}

export const countFundPerformance = (fundData) => {
    return [
        //        countPerformanceInRange(fundData, 126, 232),
        20, 10, 25, 25, 35
    ];
}
/*
    watchList: {
      [key: string]: {
        "1Y": number;
        "6M": number;
        "3M": number;
        "1M": number;
        "1W": number;
      };
    },
    riskFactor?: number
*/
// getRecommendation v 12.28
export const getRecommendation = (watchlist, riskFactor = 0) => {
    const retn = [];
    var retn2 = [];

    watchlist.forEach((f) => {
        const scoreRawArr = [
            f["6M"],
            f["3M"],
            f["1M"],
            f["1W"],
            f["1D"],
        ];
        const scoreArr = scoreRawArr.map(s => (10 - s) / 10);
        const decileArr = scoreArr.map(
            (d) =>
                ((percentile(scoreArr, d) - 50) / 1) * findRange(scoreArr) +
                findAverage(scoreArr)
        );
        const decileArr_adj = decileArr.map((d) => d + findMag(decileArr));
        const decileArr_adj_norm = decileArr_adj.map(
            (d) => (d / Math.max(...decileArr_adj)) * Math.max(...scoreArr)
        );
        let init_score = 5;
        for (let i = 1; i < decileArr_adj_norm.length; i++) {
            if (decileArr_adj_norm[i] > decileArr_adj_norm[i - 1] + 0.15)
                init_score += 0.5 * i;
            else if (decileArr_adj_norm[i] > decileArr_adj_norm[i - 1] + 0.075)
                init_score += 0.25 * i;
            else if (decileArr_adj_norm[i] > decileArr_adj_norm[i - 1] + 0.035)
                init_score += 0.125 * i;
            else if (decileArr_adj_norm[i] < decileArr_adj_norm[i - 1] - 0.15)
                init_score -= 0.5 * i;
            else if (decileArr_adj_norm[i] < decileArr_adj_norm[i - 1] - 0.075)
                init_score -= 0.25 * i;
            else if (decileArr_adj_norm[i] < decileArr_adj_norm[i - 1] - 0.035)
                init_score -= 0.125 * i;
            else init_score += 0;
        }
        retn.push({ ...f, recmdScore: init_score, recommend: "No" });
    });
    retn.forEach((d) => {
        if (
            d.recmdScore >= Math.max(...retn.map((f) => f.recmdScore)) - 0.25 &&
            d.recmdScore >= 5.6 &&
            (!d['ytd'] || (d['ytd'] ?? 0) >= 15) &&
            (!d['mtd'] || (d['mtd'] ?? 0) >= 3) &&
            d.recmdScore >
            retn.map((f) => f.recmdScore).reduce((a, b) => a + b, 0)
            / (retn.length ?? 1)
        )
            d['recommend'] = "Yes";
        retn2.push(d);
    });
    return retn2;
}

const percentile = (arr, val) =>
    (100 *
        arr.reduce(
            (acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0),
            0
        )) /
    arr.length;

const findAverage = (arr = []) =>
    arr.length ? arr.reduce((a, b) => a + b) / arr.length : 0;

const findRange = (arr = []) =>
    Math.abs(Math.max(...arr) - Math.min(...arr));

const findMag = (arr = []) =>
    Math.max(Math.abs(Math.max(...arr)), Math.abs(Math.min(...arr)));

export const countFundsReturn = ({ watchlist, fundsData, startDate, endDate, weightLow = 0, weightHigh = 1 }) => {
    var ret = [];
    var cur_date = new Date(startDate);
    var first_date = new Date(startDate);
    var last_date = addDay(cur_date, -1);
    var end_date = new Date(endDate) ?? new Date();
    var fundsDataMap = {};
    for (var i in watchlist) {
        const data = getFundData(watchlist[i].fund, fundsData);
        fundsDataMap[watchlist[i].fund] = data ?? [];
    }

    while (dayCompare(cur_date, end_date) <= 0) {
        var d0 = {}, d1 = {};
        d0['date'] = dateToString(cur_date);
        d1['date'] = d0['date'];
        var min = 10000;
        var max = -10000;
        for (var i in watchlist) {
            var r = countReturn(watchlist[i].fund, fundsDataMap[watchlist[i].fund], first_date, cur_date);
            if (r != "na") {
                if (r < min) {
                    min = r;
                }
                if (r > max) {
                    max = r;
                }
            }
        }
        if (min < 10000 && max > -10000) {
            var min1 = Number(min) + Number(max - min) * weightLow;
            var max1 = Number(min) + Number(max - min) * weightHigh;

            ret.push({
                date: dateToString(cur_date),
                return: [min1 * 100, max1 * 100],
            })
        }
        last_date = cur_date;
        cur_date = addDay(cur_date, 1);
    }
    return ret;
}