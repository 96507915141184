import utilities from '../../utils/utilities';

export const addOnBoardInfo = (onboardInfo) => {
    return {
        type: 'ADD_ONBOARD_INFO',
        payload: onboardInfo
    };
};

const funds = [{ fund: 'wei1' }, { fund: 'wei2' }, { fund: 'wei3' }]

export const addFundSelections = (selectedFund) => {
    return {
        type: 'ADD_AVAILABLE_FUND',
        payload: selectedFund
    }
}

export const removeFundSelections = (key) => {
    return {
        type: 'REMOVE_FUND_SELECTION',
        payload: key
    }
}

export default (state = { 'available_funds': funds }, action) => {
    switch (action.type) {
        case 'ADD_ONBOARD_INFO': {
            return { ...state, ...action.payload };
        };
        case 'ADD_AVAILABLE_FUND': {
            return { ...state, 'available_funds': [...state['selected_fund'], action.payload] };
        };
        case 'REMOVE_FUND_SELECTION': {
            // convert data format to fit the keys and values of the table shown on the last step
            const fundsWithKeys = utilities.addKeyToFunds(state['available_funds']);

            const filteredFunds = fundsWithKeys.filter((item, _) => action.payload !== item.key);
            const fundsWithOutKeys = filteredFunds.map((fund, _) => {
                return { fund: fund.fund };
            });
            return { ...state, 'available_funds': [...fundsWithOutKeys] };
        }
    }
    return state;
};
