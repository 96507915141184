import Axios from 'axios';
import { getJwtToken } from '../utils/token';
import { URLS } from '../utils/Constants';

const DEFAULT_TIMEOUT = 60000;

Axios.defaults.timeout = DEFAULT_TIMEOUT;

Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.common['Authorization'] = getJwtToken();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
Axios.interceptors.response.use(
  function (response) {
    // Do something before request is sent
    const data = response.data;
    const { onboarded, onboard_step } = data;
    if (onboard_step != undefined && !onboarded) {
      const url = `${URLS.ON_BOARD}?step=${onboard_step}&onboarded=false`;
      window.location = url;
    } else {
      return response;
    }
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location = URLS.LOGIN;
    }
    return Promise.reject(error);
  }
);
